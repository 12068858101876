import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {LoaderService} from './loader.service';
import {BaseRequestService} from './base.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {
  omitDomains = ['securitycenter', 'demo', 'chandralayout', 'chandukrishna'];
  constructor(private injector: Injector, private auth: AuthenticationService, private ngZone: NgZone,
              private baseService: BaseRequestService, private toastr: ToastrService,
              private loaderService: LoaderService) {
  }
  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      this.loaderService.display(false);
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      if (error.status === 401) {
        error = null;
        const path = window.location.pathname;
        if (path !== '' && path !== '/login' && path !== '/oauth2/auth' && path !== '/login/oauth2_authorization_code_grant_callback') {
          localStorage.removeItem('isLoggedin');
          this.ngZone.run(x => this.auth.logout());
        }
      } else if (error.status === 403) {
        const path = window.location.pathname;
        this.loaderService.display(false);
        setTimeout(() => { this.loaderService.display(false); }, 1500);
        this.toastr.error('Unauthorized: Access is denied.');
        this.loaderService.tableProgress(false);
      }  else if (error.status === 500) {
        const path = window.location.pathname;
        setTimeout(() => { this.loaderService.display(false); }, 1000);
        /*this.updateErrLogs(error);*/
        const getCircularReplacer = () => {
          const seen = new WeakSet();
          return (key, value) => {
            if (typeof value === 'object' && value !== null) {
              if (seen.has(value)) {
                return;
              }
              seen.add(value);
            }
            return value;
          };
        };
        let errorLogsData;
        if (localStorage.getItem('errorLogs') && localStorage.getItem('errorLogs') !== null) {
          errorLogsData = JSON.parse(localStorage.getItem('errorLogs'));
        } else {
          errorLogsData = [];
        }
        const err = JSON.stringify(error, getCircularReplacer());
        const domain = new URL(error.url);
        let msgSend = true;
        this.omitDomains.forEach(obj => {
          if (domain.hostname.indexOf(obj) > -1 ) {
            msgSend = false;
          }
        });
        if (msgSend) {
          this.sendSlackMessage(domain.hostname + '\n```' + err + '```');
        }
        errorLogsData.push(err);
        localStorage.setItem('errorLogs', JSON.stringify(errorLogsData));
        error = null;
      }
    } else {
      console.log(error);
      // this.updateErrLogs(error);
    }
  }
  sendSlackMessage(msg) {
    this.baseService.doRequest('/vulnerability/api/report_publisher/dummy/sendSlackMsg',
      'post', {message: msg})
      .subscribe(result => {
        console.log('500 error captured!');
      });
  }
}

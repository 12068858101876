import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GestureConfig } from '@angular/material/core';
import { MaterialModule} from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule} from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomIconService } from './_services/custom-icon.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { UserIdleModule } from 'angular-user-idle';
import { CustomErrorHandlerService } from './_services/customErrorHandler.service';
import { AngularSplitModule } from 'angular-split';
import { CKEditorModule } from 'ckeditor4-angular';
import {JoyrideModule} from 'ngx-joyride';

/*import { NgxCaptureModule } from 'ngx-capture';*/

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    JoyrideModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    MaterialModule,
    DragDropModule,
    CKEditorModule,
    AngularSplitModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 8000, progressBar: true, enableHtml: true,
      closeButton: true,
      preventDuplicates: true,
    }),
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DeviceDetectorModule.forRoot(),
    PDFExportModule,
    NgxMatSelectSearchModule,
    UserIdleModule.forRoot({idle: 1800, timeout: 900, ping: 450})
  ],
  providers: [
    CustomIconService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000}},
    { provide: ErrorHandler, useClass: CustomErrorHandlerService},
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import {Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {ElementRef, Renderer2} from '@angular/core';
import {Network, DataSet, Node, Edge, IdType} from 'vis';
import {CommonService} from '../../_services/common.services';

declare var vis: any;

@Component({
  selector: 'app-network-graph',
  templateUrl: './network-graph.component.html',
  styleUrls: ['./network-graph.component.css']
})
export class NetworkGraphComponent implements OnInit {
  @Output() nodeCleared = new EventEmitter<void>();
  @Output() nodeClicked = new EventEmitter<void>();
  @ViewChild('siteConfigNetwork', {static: true}) networkContainer: ElementRef;
  public network: any;

  constructor(private commonService: CommonService) {
    this.commonService.selectedNodeChange.subscribe(node => {
      this.clearNode(node.id);
    });
  }
  options: any;
  scaleLevel = 1;
  @Input() treedata: any;
  currentState: any;
  loadVisTree() {
    const _thisRef = this;
    const container = this.networkContainer.nativeElement;
    let options = {
      autoResize: true,
      height: '100%',
      width: '100%',
      /*nodes: {
        borderWidth:4,
        size:30,
        color: {
          border: '#222222',
          background: '#666666'
        },
        font:{color:'#eeeeee'}
      },*/
      nodes: {
        borderWidth: 2,
        size: 30,
        /*color: { border: '#39469f'},*/
        shadow: true,
        scaling: {
          min: 16,
          max: 32
        }
      },
      edges: {
        smooth: {
          type: 'continuous',
          roundness: 0.4
        },
        shadow: true
      },
      interaction: {
        hover: true,
        selectable: true,
        dragNodes: false,
        tooltipDelay: 100,
        navigationButtons: true,
        keyboard: true
      },
      layout: {
            randomSeed: 2
            /*hierarchical: {
                direction: 'UD',        // UD, DU, LR, RL
                sortMethod: 'directed'   // hubsize, directed
            }*/
        },
      /*layout: {
        hierarchical: {
          enabled: true,
          direction: 'UD',
          sortMethod: 'hubsize',
          /!*shakeTowards: 'leaves',*!/
          levelSeparation: 420,
          nodeSpacing: 150,
          treeSpacing: 300,
          blockShifting: false,
          edgeMinimization: false,
          parentCentralization: false
        }
      },*/
      physics: {
            enabled: false
        },
      /*physics: {stabilization: false}*/
      /*physics: {
        forceAtlas2Based: {
            gravitationalConstant: -26,
            centralGravity: 0.005,
            springLength: 230,
            springConstant: 0.18
        },
        maxVelocity: 146,
        solver: 'forceAtlas2Based',
        timestep: 0.35,
        stabilization: {
            enabled: true,
            iterations: 2000,
            updateInterval: 50
        }
    }*/
    };

    let options1 = {
      nodes: {
        shape: 'dot',
        size: 16,
      },
      edges: {
        smooth: {
          type: 'continuous',
          roundness: 0.4
        },
        shadow: true
      },
      interaction: {
        hover: true,
        selectable: true,
        dragNodes: false,
        tooltipDelay: 100,
        navigationButtons: true,
        keyboard: true
      },
      physics: {
        stabilization: true,
      },
      layout: {randomSeed: 0},
      /*layout: {
            randomSeed: 2
            /!*hierarchical: {
                direction: 'UD',        // UD, DU, LR, RL
                sortMethod: 'directed'   // hubsize, directed
            }*!/
        }, physics: {
                    forceAtlas2Based: {
                        gravitationalConstant: -26,
                        centralGravity: 0.005,
                        springLength: 230,
                        springConstant: 0.18
                    },
                    maxVelocity: 146,
                    solver: 'forceAtlas2Based',
                    timestep: 0.35,
                    stabilization: {iterations: 150}
                }*/
    };

    this.options = {
      physics: {
        stabilization: true,
      },
      nodes: {
        borderWidth: 3,
        size: 10,
        fixed: {x: true, y: true},
        color: {
          border: 'orange',
          highlight: {
            border: '#FF0000'
          }
        }
      },
      edges: {
        smooth: {
          type: 'continuous',
          roundness: 0.4
        },
        shadow: false
      },
      layout: {randomSeed: 0},
      interaction: {
        hover: true,
        selectable: true,
        dragNodes: false,
        tooltipDelay: 100,
        navigationButtons: true,
        keyboard: true
      },
    };

    this.network = new vis.Network(container, this.treedata, this.options);
    if (this.treedata.nodes.length > 100) {
      this.scaleLevel = .5;
      // this.clusterByHubsize();
    }

    this.fitNetwork(2000);

    this.network.on('zoom', (params) => {
        this.currentState = params;
    });

    this.network.on('selectNode', function (params) {
      if (params.nodes.length === 1) {
        if (_thisRef.network.isCluster(params.nodes[0])) {
          _thisRef.network.openCluster(params.nodes[0]);
        } else {
          _thisRef.updateData(params);
          const selectedNodeId = params.nodes[0];
          const node = _thisRef.network.body.nodes[selectedNodeId];
          node.setOptions({
            font: {
              size: 20, color: '#39469f'
            }, highlight: {background: 'red', border: '#39469f'}
          });
        }
        _thisRef.fitNetwork(2000);
      }
    });

    this.network.on('deselectNode', function (params) {
      const deselectedNodeId = params.previousSelection.nodes[0];
      const node = _thisRef.network.body.nodes[deselectedNodeId];
      node.setOptions({
        font: {
          size: 12, color: 'black'
        }
      });
      _thisRef.dselectData(params);
      _thisRef.fitNetwork(4000);
    });
  }
  fitNetwork(delay) {
    return false;
    delay = (delay) ? delay : 2000;
    setTimeout(() => {
      if (this.currentState) { return false; }
      this.network.fit();
    }, delay);
  }
  clusterByHubsize() {
    this.network.setData(this.treedata);
    const clusterOptionsByData = {
      processProperties: ((clusterOptions, childNodes) => {
        clusterOptions.label = '[' + childNodes.length + ']';
        return clusterOptions;
      }),
      clusterNodeProperties: {borderWidth: 3, shape: 'box', font: {size: 30}}
    };
    this.network.clusterByHubsize(undefined, clusterOptionsByData);
  }

  clearNode(deselectedNodeId) {
    console.log(deselectedNodeId);
    const node = this.network.body.nodes[deselectedNodeId];
    node.setOptions({
      font: {
        size: 12
      }
    });
    this.fitNetwork(1000);
  }

  dselectData(params) {
    this.nodeCleared.emit();
  }

  updateData(params) {
    this.nodeClicked.emit(params);
  }

  ngOnInit() {
    if (this.treedata) {
      this.loadVisTree();
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.loadVisTree();
  }
}

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {Observable} from 'rxjs';
import {BaseRequestService} from '../_services/base.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public authenticationService: AuthenticationService) {
  }

  canActivateN( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('isLoggedin')) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  canActivate() {
    if (!this.authenticationService.isAuthenticated) {
      if ( window.location.pathname !== '' && window.location.pathname !== '/' && window.location.pathname !== '/login') {
        localStorage.setItem('path', window.location.pathname);
      }
      this.router.navigateByUrl('/login');
      return false;
    }

    // TODO additional logic will be coming in future
    return this.authenticationService.isAuthenticated;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloadpage',
  templateUrl: './downloadpage.component.html',
  styleUrls: ['./downloadpage.component.scss']
})
export class DownloadpageComponent implements OnInit {
   downloadTarget = 'agent';
  //dockerSteps:any=[];
  agent = [
    'Untar the file:   tar -zvxf LinuxAgentInstaller.tar.gz',
    'cd LinuxAgentInstaller',
    './installAgent.sh',
    'It takes 15  - 20 minutes approximately, When prompted enter your domain name',
    'When prompted for the appliance key enter the secret key that you obtain from the portal',
    'Once the Agent is installed and registered the configuration process is started and the status can be tracked in Jobs table'
  ];
  remidiation = [
    'Run the exe, click next to finish',
    'Agent installed successfully'
  ];
  dockerSteps = [
    'tar xzf scan_agent_ubuntu_docker.tar.gz',
    'cd /tmp',
    'chmod +x installNetalyticsAgentDocker.sh',
    './installNetalyticsAgentDocker.sh'
  ];

  Objectkeys = Object.keys;
  constructor() { }

  ngOnInit() {
  }

}

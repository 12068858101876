import { SelectionModel } from '@angular/cdk/collections';
import {
  Component, OnInit, OnDestroy, AfterViewInit, ViewChild, OnChanges, SimpleChanges,
  SimpleChange, Input, Output, EventEmitter, ElementRef, HostListener
} from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatMenuTrigger } from '@angular/material';
import * as XLSX from 'xlsx';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseRequestService } from 'src/app/_services/base.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoaderService } from '../../_services/loader.service';
import {SharedModule} from '../shared.module';
import {CustomErrorHandlerService} from '../../_services/customErrorHandler.service';
import { FormControl, FormControlName } from '@angular/forms';

import { ReplaySubject} from 'rxjs';
@Component({
  selector: 'app-s-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }
  constructor(private activeRoute: Router, private loaderService: LoaderService,
    private authenticationService: AuthenticationService, private baseService: BaseRequestService,
    private eRef: ElementRef, public toastr: ToastrService) {

    this.loaderService.tableProgressOff.subscribe(value => {
      this.tableOptions.loading = false;
    });
    // Debounce search.
    this.filterUpdate.pipe(
      debounceTime(1500),
      distinctUntilChanged())
      .subscribe(value => {
        this.isActionChanged = true;
        this.doFilter(value);
      });

    this.colfilterUpdate.pipe(
      debounceTime(1500),
      distinctUntilChanged())
      .subscribe(value => {
        this.doColumFilter(value);
      });
  }
  public assetDrpCtrl: FormControl = new FormControl();
  public assetDrpFilterCtrl: FormControl = new FormControl();
  public filterDrpLstFilteredList: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('listmenuTrigger', { static: false }) listmenuTrigger: MatMenuTrigger;
  @ViewChild('exportmenuTrigger', { static: false }) exportmenuTrigger: MatMenuTrigger;
  filterText: string;
  currentPageIndex: any;
  isExport = false;
  table_ID: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  _sTableOptions: any;
  isLoading = true;
  dataSource = new MatTableDataSource<any>();
  hiddenDataSource = new MatTableDataSource<any>();

  @ViewChild('MAINTABLEDIV', { static: false }) tableDiv: ElementRef;
  @ViewChild('pdf', { static: false }) pdf: PDFExportComponent;

  @Input() sTableOptions: any;
  @Output() filterCallback = new EventEmitter();
  @Output() publishCallback = new EventEmitter();
  @Output() sortCallback = new EventEmitter();
  @Output() actionCallback = new EventEmitter();
  @Output() globalActionCallback = new EventEmitter();
  @Output() pageCallback = new EventEmitter();
  @Output() refreshCallback = new EventEmitter();
  @Output() hyperlinkCallback = new EventEmitter();
  @Output() addCallback = new EventEmitter();
  @Output() compareCallback = new EventEmitter();
  @Output() timerCallback = new EventEmitter();
  @Output() checkBoxCallback = new EventEmitter();
  @Output() selectionchangeCallback = new EventEmitter();
  @Output() parentCallback = new EventEmitter();
  filterValues: string;
  filterArray: any = [];
  selectedTimer: number;
  intervalId: any;
  called = false;
  tableOptions = {
    'id': 'imaws',
    'title': '',
    'isServerSide': true,
    'selectText': 'item(s)',
    'floatingFilter': true,
    'rowSelection': true,
    'loading': false,
    'showAction': false,
    'actionMenuItems': [{ text: 'Details', icon: 'info', callback: 'editFunction' }],
    'pagination': true,
    'pageOptions': [10, 20, 30, 50, 100, 200],
    'pageSize': 50,
    'pageTotal': 0,
    'search': true,
    'showhideList': true,
    'refreshData': true,
    'dropdwn': false,
    'add': false,
    'exportExcel': true,
    'compareData': false,
    'publish': false,
    'parentCalls': []
  };

  isMUltiple = true; initSelectedValues = [];
  selection = new SelectionModel<any>(this.isMUltiple, this.initSelectedValues);
  numSelected: number;

  isCompMUltiple = true; initCompSelectedValues = [];
  compSelection = new SelectionModel<any>(this.isMUltiple, this.initCompSelectedValues);
  compNumSelected: number;

  columns = [
    {
      columnDef: 'name',
      header: 'Name',
      cell: (element: any) => `${element.name}`
    },
    {
      columnDef: 'dateOfBirth',
      header: 'Date of Birth',
      filter: 'date', cell: (element: any) => `${element.dateOfBirth}`
    },
    {
      columnDef: 'address',
      header: 'Address',
      cell: (element: any) => `${element.address}`
    }];

  public displayedColumns: Array<any>;
  public showhideList: Array<any>;
  orderlist: Array<any>;

  filterUpdate = new Subject<string>();
  colfilterUpdate = new Subject<string>();
  settingsObj = [];
  sessionData: any = [];
  searchedColName = '';
  pastIndex: any;
  isPageLoad = true;

  isActionChanged = false;
  isTablePadding = false;
  columnsCnt = 0;
  exportCnt = 0;
  isExportClick = false;

  hasSelect = false;
  hasAction = false;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if (this.isExportClick) {
        if (this.exportCnt > 1) {
          if (this.exportmenuTrigger !== undefined) {
            this.exportmenuTrigger.closeMenu();
          }
          this.exportCnt = 1;
          this.isExportClick = false;
        } else {
          if (this.listmenuTrigger !== undefined) {
            this.listmenuTrigger.closeMenu();
          }
          this.exportCnt++;
        }
      } else {
        if (this.columnsCnt > 1) {
          if (this.listmenuTrigger !== undefined) {
            this.listmenuTrigger.closeMenu();
          }
          this.columnsCnt = 1;
        } else {
          if (this.exportmenuTrigger !== undefined) {
            this.exportmenuTrigger.closeMenu();
          }
          this.columnsCnt++;
        }
      }
    } else {
      this.columnsCnt = 0;
      if (this.listmenuTrigger !== undefined) {
        this.listmenuTrigger.closeMenu();
      }
    }
  }
  columnFilter(val, col) {
    this.searchedColName = col;
    this.colfilterUpdate.next(val);
  }
  getProperty(object, propertyName ): any {
    let parts = []; let property = '';
    parts = propertyName.split('.'); const length = parts.length;
    let i = 0;
    property = object;
    for ( i = 0; i < length; i++ ) {
      property = property[parts[i]];
    }
    return property;
  }

  rowClick(row, index) {
    if (row._id) {
      this.dataSource.data.forEach(obj => {
        obj.highlighted = (obj._id === row._id) ? true : false;
      });
    } else {
      if (this.pastIndex !== undefined) {
        this.dataSource.data[this.pastIndex].highlighted = false;
      }
      this.pastIndex = index;
      this.dataSource.data[index].highlighted = !this.dataSource.data[index].highlighted;
    }
  }

  /*getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )*/



  getCustomText(obj) {
    const customText = this.sTableOptions.customText.filter(x => x.status === obj);
    if (customText.length > 0) {
      return customText[0].DisplayText;
    }
  }
  // getCustomColor(obj) {
  //   const customText = this.sTableOptions.customText.filter(x => x.status == obj);
  //   if (customText.length > 0) {
  //     return { 'background-color': customText[0].color };
  //   }
  //   return { 'background-color': '#ffffff' };
  // }

  getCustomColor(obj) {
    const customText = this.sTableOptions.customText.filter(x => x.status === obj);
    if (customText.length > 0) {
      const style = { 'background-color': customText[0].color };
      if (customText[0]['text-color']) {
        style['color'] = customText[0]['text-color'];
      }
      return style;
    }
    return { 'background-color': '#ffffff' };
  }

  getIconPath(obj, path) {
    if (this.getProperty(obj, path.columnDef) === path.success) {
      return path.successIconPath !== undefined ? path.successIconPath : 'assets/images/devices/online.svg';
    } else {
      return path.failureIconPath !== undefined ? path.failureIconPath : 'assets/images/devices/offline.svg';
    }
  }

  getIconTooltip(obj, path) {
    if (this.getProperty(obj, path.columnDef) === path.success) {
      return path.successToolTip !== undefined ? path.successToolTip : 'Online';
    } else {
      return path.failureToolTip !== undefined ? path.failureToolTip : 'Offline';
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.isActionChanged = true;
    moveItemInArray(this.showhideList, event.previousIndex, event.currentIndex);

    for (let idx = event.previousIndex; idx >= event.currentIndex; idx--) {
      this.showhideList[idx].order = idx;
    }
    // this.showhideList[event.previousIndex].order = event.previousIndex;
    // this.showhideList[event.currentIndex].order = event.currentIndex;
    this.initUpdateData();
  }

  actionCall(row, action) {
    delete row.highlighted; delete row.hovered;
    const resp = { row: row, action: action };
    if (this.tableOptions.showAction && this.tableOptions.actionMenuItems.length > 0) {
      this.actionCallback.emit(resp);
    }
  }

  globalActionCall(row, action) {
    delete row.highlighted; delete row.hovered;
    const resp = { row: row, action: action };
    if (this.tableOptions.showAction && this.tableOptions.actionMenuItems.length > 0) {
      this.globalActionCallback.emit(resp);
    }
  }

  hyperLinkCall(row, col?) {
    delete row.highlighted; delete row.hovered;
    const event = { row: row, col: col };
    this.hyperlinkCallback.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    const sTableOptions: SimpleChange = changes.sTableOptions;
    this._sTableOptions = sTableOptions.currentValue;
    if (!sTableOptions.firstChange) {
      this.initUpdateData();
    }
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.isPageLoad = false;

    if (this.intervalId !== undefined && this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }
  initUpdateData() {
    this.tableOptions = Object.assign(this.tableOptions, this._sTableOptions['tableOptions']);
    const filterK = localStorage.getItem(this.tableOptions.id);
    if ( filterK && filterK !== null && filterK !== '') {
      this.filterText = filterK;
      if (!this.called) { setTimeout(() => { localStorage.removeItem(this.tableOptions.id);
      this.doFilter(this.filterText); this.called = true; }, 2000); }
    }
    // let sessionData = JSON.parse(sessionStorage.getItem('Settings'));
    // sessionData = sessionData === null ? [] : sessionData;
    // if (this.isPageLoad) {
    //   const items = sessionData.filter(x => x.tableID === this.tableOptions.title && x.userID === 'demo');

    //   this._sTableOptions.columns = items.length > 0 ? items[0]._settings._orderAndShowhideSettings : this._sTableOptions.columns;
    //   this._sTableOptions.sortOptions = items.length > 0 ? items[0]._settings._sortingSettings : this._sTableOptions.sortOptions;
    //   this._sTableOptions.tableOptions.pageSize = items.length > 0 ? items[0]._settings._pageSize : this.tableOptions.pageSize;
    //   this.tableOptions.pageSize = this._sTableOptions.tableOptions.pageSize;
    //   this.selectedTimer = items.length > 0 ? items[0]._settings._refreshTimetick : this.selectedTimer;
    //   this.filterText = items.length > 0 ? items[0]._settings._filter : this.filterText;


    //   const filtervalText = items.length > 0 ? items[0]._settings._filter : undefined;
    //   if (filtervalText !== undefined && filtervalText !== '') {
    //     this.doFilter(this.filterText);
    //   }

    //   const timervalue = items.length > 0 ? items[0]._settings._refreshTimetick : undefined;
    //   if (timervalue !== undefined && timervalue !== 0) {
    //     this.isActionChanged = true;
    //     this.timerCallData();
    //   }

    // }

    // this.GetFinalColumns([], this._sTableOptions.columns);

    if (this.isPageLoad && !this.tableOptions.pageTotal ) {
      const queryParam = {
        'query':
        {
          'bool':
          {
            'must': [{ 'match': { 'family.keyword': 'settings' } },
            { 'match': { 'species.keyword': 'user' } },
            { 'match': { 'tablename.keyword': this.tableOptions.id.toLowerCase() } }]
          }
        }
      };

      this.baseService.doRequest('/vulnerability/api/user_settings',
        'get', null, { query: queryParam }).subscribe(res => {
          if (res.status === 'ok') {
           // this.initUpdateData();
            this.table_ID = res.msg['data'].length > 0 ? res.msg['data'][0]['_id'] : undefined;
            const items = res.msg['data'].length > 0 ?
              JSON.parse(res.msg['data'][0]['userdata']) : res.msg['data'];

            this._sTableOptions.columns = items._orderAndShowhideSettings !== undefined ?
              this.GetFinalColumns(items._orderAndShowhideSettings, this._sTableOptions.columns) : this._sTableOptions.columns;
            // this._sTableOptions.columns = items._orderAndShowhideSettings != undefined ?
            // items._orderAndShowhideSettings : this._sTableOptions.columns;
            this._sTableOptions.sortOptions = items._sortingSettings !== undefined ?
              items._sortingSettings : this._sTableOptions.sortOptions;
            this._sTableOptions.tableOptions.pageSize = items._pageSize !== undefined ? items._pageSize : this.tableOptions.pageSize;
            this.tableOptions.pageSize = this._sTableOptions.tableOptions.pageSize;
            this.selectedTimer = items._refreshTimetick !== undefined ? items._refreshTimetick : this.selectedTimer;

            //  this.filterText = items._filter != undefined ? items._filter : this.filterText;
            // const filtervalText = items._filter != undefined ? items._filter : undefined;
            // if (filtervalText !== undefined && filtervalText !== '') {
            //   this.doFilter(this.filterText);
            // }

            const timervalue = items._refreshTimetick !== undefined ? items._refreshTimetick : undefined;
            if (timervalue !== undefined && timervalue !== 0) {
              this.isActionChanged = true;
              this.timerCallData();
            }
          }

          this.setTableOptions(this._sTableOptions.tableOptions);
          // else {
          //   this.toastr.error(res.msg);
          // }
        }, error => {
          this.setTableOptions(this._sTableOptions.tableOptions);
        });
    } else {
      this.clearSelection();
      this.setTableOptions(this.tableOptions);
    }

    if (this.isPageLoad) {
      this.isPageLoad = false;
    }
  }


  setTableOptions(tblOptions) {
    this.displayedColumns = [];
    this.orderlist = [];
    this.showhideList = this._sTableOptions.columns;

    this._sTableOptions.columns.map(c => {
      if (c.visible) {
        this.displayedColumns.push(c.columnDef);
        this.orderlist.push(c);
      }
    });

    if (tblOptions.compareData) {
      this.displayedColumns.push('compare');
    }

    if (tblOptions.showAction) {
      this.displayedColumns.push('action');
    }

    if (tblOptions.rowSelection) {
      this.displayedColumns.unshift('select');
    }

    this.dataSource.data = this._sTableOptions.pageData;
    this.dataSource.sort = this.sort;


    if (!tblOptions.isServerSide) {
      this.dataSource.paginator = this.paginator;
    }
    this.filterArray = this.dataSource.filteredData;
  }

  // GetFinalColumns(savedColumns, definedColumns) {
  //   const finalcols = [];
  //   if (savedColumns.length >= definedColumns.length) {
  //     savedColumns.forEach(sCol => {
  //       try {
  //         for (let dCol = 0; dCol < definedColumns.length; dCol++) {
  //           if (sCol.columnDef == definedColumns[dCol].columnDef) {
  //             finalcols.push(sCol);
  //             break;
  //           }
  //           if (dCol == definedColumns.length - 1) {
  //             finalcols.push(definedColumns[dCol]);
  //           }
  //         }
  //       } catch (e) {
  //         e = null;
  //       }
  //     });
  //   } else if (definedColumns.length > savedColumns.length) {
  //     definedColumns.forEach(dCol => {
  //       for (let sCol = 0; sCol < savedColumns.length; sCol++) {
  //         try {
  //           if (dCol.columnDef == savedColumns[sCol].columnDef) {
  //             finalcols.push(savedColumns[sCol]);
  //             break;
  //           }
  //           if (sCol == savedColumns.length - 1) {
  //             finalcols.push(dCol);
  //           }
  //         } catch (e) {
  //           e = null;
  //         }
  //       }
  //     });
  //   }
  //   return finalcols;
  // }

  GetFinalColumns(savedColumns, definedColumns) {
    definedColumns.forEach(obj => {
      savedColumns.forEach(obj1 => {
        if (obj1.columnDef === obj.columnDef) {
          obj.visible = obj1.visible;
          obj.order = obj1.order;
        }
      });
    });
    try {
      definedColumns = definedColumns.sort((a, b) => (a.order > b.order) ? 1 : -1);
    } catch (e) {
      e = null;
    }
    return definedColumns;
  }

  showHideColumn(i, val): void {
    val.visible = !val.visible;
    const index = this.orderlist.findIndex(x => x.columnDef === val.columnDef);
    if (index >= 0) {
      this.orderlist.splice(index, 1);
    } else {
      this.orderlist.push(val);
    }
    this.displayedColumns = [];
    this.orderlist = this.orderlist.sort((a, b) => a.order - b.order);

    if (this.tableOptions.compareData) {
      this.displayedColumns.push('compare');
    }

    if (this.tableOptions.rowSelection) {
      this.displayedColumns.push('select');
    }

    this.orderlist.forEach(element => {
      this.displayedColumns.push(element.columnDef);
    });
    if (this.tableOptions.showAction) {
      this.displayedColumns.push('action');
    }

    this.isActionChanged = true;

    this.initUpdateData();
    this.SaveSettings();
  }

  ngAfterViewInit(): void {
    this.initUpdateData();
    setTimeout(() => {
      this.isLoading = false;
    });
  }

  pageChanged(event) {

    this.isActionChanged = true;

    this.currentPageIndex = event.pageIndex;
    this.tableOptions.pageSize = event.pageSize;

    if (this.tableOptions.isServerSide) {
      this.pageCallback.emit(event);
    }

  }

  RefreshTableData() {
    this.refreshCallback.emit();
  }
  openListColumns() {
    this.isExportClick = false;
    this.columnsCnt++;
    if (this.columnsCnt > 1) {
      this.columnsCnt = 1;
    }

  }
  openListExport() {
    this.exportCnt++;
    this.isExportClick = true;
    if (this.exportCnt > 1) {
      this.exportCnt = 1;
    }
  }

  timerCallData() {
    // this.listmenuTrigger.closeMenu();
    clearInterval(this.intervalId);
    this.intervalId = undefined;

    if (this.selectedTimer !== undefined && Number(this.selectedTimer) !== 0) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.timerCallback.emit(this.selectedTimer);
      }, Number(this.selectedTimer) * 1000);
    }

  }
  filterDrpLstChange(data) {
    console.log(data);
    this.selectionchangeCallback.emit(data);

  }

  ParentCalls(id) {
    this.parentCallback.emit(id);
  }

  AddTableRecord() {
    // this.listmenuTrigger.closeMenu();
    this.addCallback.emit();
  }
  publish() {
    this.publishCallback.emit();
  }
  CompareData(row) {
    if (row.length <= 1) {
      this.toastr.info('Minimum of two files are required to compare');
      return false;
    }

    if (row.length > 2) {
      this.toastr.info('It is not allowed to compare more than 2 records');
      return false;
    }
    const resp = { row: row };
    this.compareCallback.emit(resp);
  }

  clearSelection() {
    this.selection.clear();
    this.numSelected = undefined;
  }

  clearCompareSelection() {
    this.selection.clear();
    this.numSelected = undefined;
  }

  clearCompSelection() {
    this.compSelection.clear();
    this.compNumSelected = undefined;
  }

  clearCompCompareSelection() {
    this.compSelection.clear();
    this.compNumSelected = undefined;
  }

  isAllSelected() {
    this.numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    if (this.sTableOptions.tableOptions.isCheckboxSelection) {
      this.checkBoxCallback.emit(this.selection.selected);
    }
    return this.numSelected === numRows;
  }

  isAllCompSelected() {
    this.compNumSelected = this.compSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.compNumSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    if (this.sTableOptions.tableOptions.isCheckboxSelection) {
      this.checkBoxCallback.emit(this.selection.selected);
    }
  }

  masterCompToggle() {
    this.isAllCompSelected() ?
      this.compSelection.clear() :
      this.dataSource.data.forEach(row => this.compSelection.select(row));
  }

  customSort(event) {
    // console.log(this._sTableOptions.sortOptions);
    // {active: 'name', direction: 'asc'}
    this._sTableOptions.sortOptions = event;
    if (this.tableOptions.isServerSide && event.direction !== '') {
      this.sortCallback.emit(event);
    }
  }
  exportAllData() {
    const keymap = {};
    this._sTableOptions['columns'].forEach(obj => {
      keymap[obj.columnDef] = obj.header;
    });
    keymap['_id'] = (keymap['_id']) ? keymap['_id'] : '_id';
    this.loaderService.display(true);
    const name =  (this.tableOptions.title !== '' ) ? this.tableOptions.title.replace(/[^A-Z0-9]/ig, '_') : 'Report';
    this.baseService.doRequest('/vulnerability/api/report_generator/dummy/generateReport', 'post',
      { query: this._sTableOptions['query'], keyMap: keymap, name: name }).subscribe(result => {
        this.loaderService.display(false);
        if (result.status === 'ok') {
          window.open(result.msg, '_blank');
        } else {
          this.toastr.error(result.msg);
        }
      }, error => {
        this.loaderService.display(false);
      });
  }


  ExportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableDiv.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const range = XLSX.utils.decode_range(wb.Sheets.Sheet1['!ref']);
    let C, R = range.s.r;
    for (C = range.s.c; C <= range.e.c; ++C) {
      const cell = wb.Sheets.Sheet1[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */

      if (cell.v === 'more_vert') {
        delete wb.Sheets.Sheet1[XLSX.utils.encode_cell({ c: C, r: R })];
      }
    }
    for (let index = 1; index <= range.e.r; index++) {
      for (C = range.s.c; C <= (range.e.r * range.e.c); ++C) {
        const cell = wb.Sheets.Sheet1[XLSX.utils.encode_cell({ c: C, r: index })]; /* find the cell in the first row */

        if (cell.z === 'm/d/yy') {
          cell.z = 'm/d/yy hh:mm:ss AM/PM';
        }
        if (cell.v === 'more_vert') {
          delete wb.Sheets.Sheet1[XLSX.utils.encode_cell({ c: C, r: index })];
          C = 0;
          break;
        }
      }
    }

    /* save to file */
    if (this.tableOptions.title !== '') {
      XLSX.writeFile(wb, this.tableOptions.title + '.xlsx');
    } else {
      XLSX.writeFile(wb, this.tableOptions.id + 'EXPORT.xlsx');
    }
  }
  SaveSettings() {
    // this.settingsObj = [];
    // const item = {
    //   tableID: '',
    //   userID: '',
    //   _settings:
    //   {
    //     _sortingSettings: [],
    //     _orderAndShowhideSettings: [],
    //     _filter: '',
    //     _refreshTimetick: 0,
    //     _pageSize: 0
    //   }
    // };
    // item.tableID = this.tableOptions.title;
    // item.userID = 'demo';
    // item._settings._sortingSettings = this._sTableOptions.sortOptions;
    // item._settings._orderAndShowhideSettings = this._sTableOptions.columns;
    // item._settings._filter = this.filterText;
    // item._settings._refreshTimetick = this.selectedTimer;
    // item._settings._pageSize = this.tableOptions.pageSize;
    // const r = this.settingsObj.push(item);
    // // console.log(this.settingsObj);

    // this.sessionData = JSON.parse(sessionStorage.getItem('Settings'));
    // if (this.sessionData === null) {
    //   this.sessionData = this.settingsObj;
    // } else {
    //   const result = this.sessionData.filter(x => x.tableID === item.tableID && x.userID === 'demo');
    //   if (result.length <= 0) {
    //     this.sessionData.push(item);
    //   } else {
    //     const index = this.sessionData.indexOf(result[0]);
    //     this.sessionData[index] = item;
    //   }
    // }
    // sessionStorage.setItem('Settings', JSON.stringify(this.sessionData));

    const _settings: any = {
      _sortingSettings: [],
      _orderAndShowhideSettings: [],
      _filter: '',
      _refreshTimetick: 0,
      _pageSize: 0
    };
    _settings._sortingSettings = this._sTableOptions.sortOptions;
    _settings._orderAndShowhideSettings = this._sTableOptions.columns;
    //  _settings._filter = this.filterText;
    _settings._refreshTimetick = this.selectedTimer;
    _settings._pageSize = this.tableOptions.pageSize;

    const method = this.table_ID ? 'put' : 'post';
    const url = this.table_ID ? `/vulnerability/api/user_settings/${this.table_ID}` : `/vulnerability/api/user_settings`;
    this.baseService.doRequest(url, method, {
      'tablename': this.tableOptions.id.toLowerCase(),
      'username': this.authenticationService.currentUser.userName,
      'userdata': JSON.stringify(_settings)
    }).subscribe(result => {
      if (result.status === 'ok') {
        this.sessionData = [];
        this.toastr.success('Settings updated');
        this.sessionData = JSON.parse(result.msg[0]['userdata']);
      } else {
        this.toastr.error(result.msg);
      }
    });
  }

  doFilter = (value: string) => {
    // this.isActionChanged = true;
    localStorage.setItem(this.tableOptions.id, value);
    if (this.tableOptions.isServerSide) {
      this.filterCallback.emit(value);
    } else {
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
  }
  doColumFilter = (value: string) => {
    if (this.tableOptions.isServerSide) {
      this.filterCallback.emit(value);
    } else {
      this.filterValues = value.toLowerCase();
      this.dataSource.data = this.filterArray.filter(row => row[this.searchedColName].toLowerCase().includes(this.filterValues));
    }
  }
}

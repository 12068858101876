import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {BaseRequestService} from './base.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class CommonService {
  previousLink = '';
  modifiedDiscoverItems: any;
  currentNode: any;
  Objectkeys = Object.keys;
  parentNode: any;
  bwListChange: Subject<object> = new Subject<object>();
  captureScreenChange: Subject<object> = new Subject<object>();
  askQueryChange: Subject<object> = new Subject<object>();
  initCaptureChange: Subject<object> = new Subject<object>();
  selectedSiteChange: Subject<any> = new Subject<any>();
  uiTreeDataMaster = {oid: '', nodes: [], isExpanded: false};
  uiTreeData = {oid: '', nodes: [], isExpanded: false};
  uiTreeDataChange: Subject<any> = new Subject<any>();
  selectedNode = {};
  selectedNodeChange: Subject<any> = new Subject<any>();
  expandedNode = {};
  expandedNodeChange: Subject<any> = new Subject<any>();
  networkDeviceReloadChange: Subject<any> = new Subject<any>();
  addCompanyChange: Subject<any> = new Subject<any>();
  addSiteChange: Subject<any> = new Subject<any>();
  moveToGroupChange: Subject<any> = new Subject<any>();
  checkNode = {};
  searchNodeChange: Subject<any> = new Subject<any>();
  selectedNodeCheck: Subject<any> = new Subject<any>();
  neta_utils: any;
  camio_utils: any;
  timeZoneList: any;
  currentTimezone: any;
  constructor(public baseRequestService: BaseRequestService, private location: Location, private router: Router) {
    this.neta_utils = {
      stoh: sec => {
        if (!sec) {
          return sec;
        } else {
          let t = Math.floor(sec / 100);
          let years;
          let months;
          let days;
          if (t > 86400) {
            days = Math.floor(t / 86400);
            t = t - (days * 86400);
          }
          const hours = Math.floor(t / 3600);
          t = t - (hours * 3600);
          const minutes = Math.floor(t / 60);
          t = t - (minutes * 60);
          let content = '';
          if (years) {
            content += years + ' y';
          }
          if (months) {
            if (content) {
              content += ', ';
            }
            content += months + ' m';
          }
          if (days) {
            if (content) {
              content += ', ';
            }
            content += days + ' d';
          }
          if (hours || days) {
            if (content) {
              content += ', ';
            }
            content += hours + ' h';
          }
          if (content) {
            content += ', ';
          }
          content += minutes + ' m ' + t + ' s.';
          return content;
        }
      },
      truncString(text, count) {
          return text.slice(0, count) + (text.length > count ? '...' : '');
      },
    };
    this.camio_utils = {
      stringToJSON: (value) => {
        let val = value.replace(/u'/g, '"'); val = val.replace(/'/g, '"');
         val = val.replace(/True/g, 'true'); val = val.replace(/False/g, 'false');
        return (value && value !== '') ? JSON.parse(val) : '';
      },
      arrayToString: (value) => {
        return value.join(', ');
      },
      formatMBSize: (bytes, decimals: number = 2) => {
        if (bytes === 0) { return '0 KB'; } else if (typeof bytes === 'string') { bytes = Number(bytes); }
        const units = [ 'KB', 'MB', 'GB', 'TB', 'PB'];
        let unit = 0;
        while ( bytes >= 1024 ) {
          bytes /= 1024;
          unit ++;
        }
        return bytes.toFixed( + decimals ) + ' ' + units[ unit ];
      },
      localeDateTime: (value) => {
        if (!value) { return ''; }
        const d = new Date(value);
        return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
      },
      formatBytesSize: (bytes, decimals: number = 2) => {
        if (bytes === 0) { return '0 Bytes'; } else if (typeof bytes === 'string') { bytes = Number(bytes); }
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let unit = 0;
        while ( bytes >= 1024 ) {
          bytes /= 1024;
          unit ++;
        }
        return bytes.toFixed( + decimals ) + ' ' + units[ unit ];
      },
      formatBytesAsBits: (bytes, decimals) => {
        if (bytes === 0) { return '0 Bytes'; }
        const bits = bytes * 8;
        const k = 1024,
          dm = decimals || 2,
          sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'],
          i = Math.floor(Math.log(bits) / Math.log(k));
        return parseFloat((bits / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      formatMotionDetection: (camera) => {
        if (typeof(camera.config.doVisualMotionConfirmation) === 'undefined') {
          return 'Visual'; } else { return camera.config.doVisualMotionConfirmation ? 'Visual' : 'Basic'; }
      },
      formatVideoUpload: (camera) => {
        if (typeof(camera.config.concatMotionEvents) === 'undefined') {
          return 'Chunked'; } else { return camera.config.concatMotionEvents ? 'Single' : 'Chunked'; }
      },
      formatUploadEvents: (upload_events) => {
        if (typeof(upload_events) === 'undefined') { return 'immediately'; } else {
          return upload_events ? 'immediately' : 'upon request'; }
      },
      getCameraIp: (cam) => {
        return cam.networkConfigurationActual && cam.networkConfigurationActual.ipAddress
          ? cam.networkConfigurationActual.ipAddress : 'unknown';
      },
      getCameraRecordingSchedule: (cam) => {
        return cam && cam.actual_values && cam.actual_values.recording_schedule ?
          cam.actual_values.recording_schedule.options[0].value : '24x7';
      },
      getCameraPlan: (camera) => {
        if (camera.plan.planName) {
          return camera.plan.planName;
        }
        return 'MISSING!';
      },
      isExpired: (value) => {
        const nowDate = new Date();
        const expiredDate = new Date(value);
        return nowDate > expiredDate;
      },
      getCameraState: (box, localCameraId) => {
        if (!localCameraId) { return ''; }
        return box.state.camera_info[localCameraId];
      }
    };
  }
  updateNodes(parentNode, currentNode) {
    this.currentNode = currentNode;
    this.parentNode = parentNode;
  }
  buildQuery(data) {
    const _baseQuery = {'query': {'bool': {'must': []}}};
    this.Objectkeys(data).forEach(obj => {
      const tmpObj = {match: {}};
      tmpObj['match'][obj] = data[obj];
      _baseQuery.query.bool.must.push(tmpObj);
    });
    return _baseQuery;
  }
  onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
  }
  getDomain(url) {
    const hostName = url;
    let domain = hostName;
    if (hostName != null) {
      const parts = hostName.split('.').reverse();
      if (parts != null && parts.length > 1) {
        domain = parts[1] + '.' + parts[0];
        if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
          domain = parts[2] + '.' + domain;
        }
      }
    }
    return domain;
  }
  reloadDevice(deviceId) {
    this.networkDeviceReloadChange.next(deviceId);
  }

  getTimeZone() {
    const offset = (new Date()).getTimezoneOffset();
    const timezones = {
    '-12': 'Pacific/Kwajalein',
    '-11': 'Pacific/Samoa',
    '-10': 'Pacific/Honolulu',
    '-9': 'America/Juneau',
    '-8': 'America/Los_Angeles',
    '-7': 'America/Denver',
    '-6': 'America/Mexico_City',
    '-5': 'America/New_York',
    '-4': 'America/Caracas',
    '-3.5': 'America/St_Johns',
    '-3': 'America/Argentina/Buenos_Aires',
    '-2': 'Atlantic/Azores',
    '-1': 'Atlantic/Azores',
    '0': 'Europe/London',
    '1': 'Europe/Paris',
    '2': 'Europe/Helsinki',
    '3': 'Europe/Moscow',
    '3.5': 'Asia/Tehran',
    '4': 'Asia/Baku',
    '4.5': 'Asia/Kabul',
    '5': 'Asia/Karachi',
    '5.5': 'Asia/Calcutta',
    '6': 'Asia/Colombo',
    '7': 'Asia/Bangkok',
    '8': 'Asia/Singapore',
    '9': 'Asia/Tokyo',
    '9.5': 'Australia/Darwin',
    '10': 'Pacific/Guam',
    '11': 'Asia/Magadan',
    '12': 'Asia/Kamchatka'
  };
    this.currentTimezone = timezones[-offset / 60];
    const _timeZone = localStorage.getItem('_timeZones');
    if (_timeZone && _timeZone !== null && _timeZone.length > 0 ) {
      this.timeZoneList = JSON.parse(_timeZone);
    } else {
      this.baseRequestService.doRequest(`/vulnerability/api/report_generator/dummy/get_supportedTimeZones`, 'post')
        .subscribe(result => {
        if (result.status === 'ok') {
          localStorage.setItem('_timeZones', JSON.stringify(result.msg));
          this.timeZoneList = result.msg;
        } else {
          this.timeZoneList = [];
        }
      });
    }
  }

  getRoot() {
    // this.spinnerService.start();
    this.baseRequestService.doRequest('/api/tree/dummy/getRoot', 'post')
      .subscribe(result => {
      // this.spinnerService.stop();
      if (result.status === 'ok') {
        this.parentNode = undefined;
        result.msg.name = location.hostname.substring(0,
          location.hostname.indexOf(this.getDomain(location.hostname)) - 1).toUpperCase();
        result.msg.isExpanded = true;
        this.uiTreeData = result.msg;
        this.uiTreeData.nodes = [];
        this.selectedNode = {'parentNode': undefined, 'node': this.uiTreeData};
        this.updateNodes(undefined, result.msg);
        // this.selectedNodeChange.next(this.selectedNode);
        this.getNextLevel(this.uiTreeData);
        this.uiTreeDataChange.next(this.uiTreeData);
      } else {

      }
    });
  }
  camelToHuman(input, uppercaseFirst) {
    if (typeof input !== 'string') {
        return input;
    }
    let result = input.replace(/([a-z\d])([A-Z])/g, '$1' + (' ' || '_') + '$2');
    if (result.indexOf('_') > -1 ) {
      result = result.replace(/(?:_| |\b)(\w)/g, function(key, p1) {
        return p1.toUpperCase(); } ).replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    if (uppercaseFirst) {
        result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }
  updateUiTreeData(data) {
    this.uiTreeData = data;
    this.uiTreeDataChange.next(this.uiTreeData);
  }
  loadingNodes(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = {name: 'Loading...'};
    }
    return arr;
  }

  backToParent() {
    if (this.previousLink && this.previousLink !== '') {
      if (this.location.path() === this.previousLink ) {
        this.location.back();
      } else {
        this.router.navigateByUrl(this.previousLink);
      }
    } else {
      this.router.navigateByUrl('/network/devices');
    }
  }
  treeSearch(name) {
    this.baseRequestService.doRequest(`/api/tree/dummy/search`, 'post', {name: name})
      .subscribe(result => {
        if (result.status === 'ok') {
          const searchK = {
            '_id': 'searchResult', 'oid': 'root', 'name': 'Search Result', 'level': 0, 'link': '',
            'nodes': (result.msg === '') ? [] : result.msg, isExpanded: true
          };
          searchK['children'] = [];
          if (result.msg !== '') {
            result.msg.forEach(obj => {
              searchK['children'].push(obj.oid);
            });
          } else {
            searchK.name = 'No Results!';
          }
          this.uiTreeData = searchK;
          if (result.msg !== '') {
            result.msg.forEach(obj => {
              if (obj['children'].length > 0) {
                this.getNextLevel(obj);
              }
            });
          }
          this.uiTreeDataChange.next(this.uiTreeData);
          // this.expandedNodeChange.next(name);
        }
      });
  }
  addCompany(name) {
    this.addCompanyChange.next(name);
  }
  addSite(ele, name) {
    ele.action = name.action;
    this.addSiteChange.next(ele);
  }
  moveToGroup(ele, name) {
    ele.action = name.action;
    this.moveToGroupChange.next(ele);
  }
  getNextLevel(node) {
    this.baseRequestService.doRequest(`/api/tree/${node.oid}/getNextLevel`, 'post')
      .subscribe(result => {
      if (result.status === 'ok') {
        if (node.oid === 'root') {
          this.uiTreeData.nodes = [];
          result.msg.forEach( obj => {
            const chilnodes = Object.assign([], obj.children);
            obj.nodes = this.loadingNodes(chilnodes);
            this.uiTreeData.nodes.push(obj);
            this.uiTreeData.isExpanded = true;
            this.uiTreeDataChange.next(this.uiTreeData);
            // if (obj.nodes.length > 0 && !obj.isExpanded) { obj.isExpanded = true; this.getNextLevel(obj); }
          });
        } else {
          this.uiTreeData.nodes.forEach( obj => {
            obj.nodes = (!obj.nodes) ? [] : obj.nodes;
            // if (obj.nodes.length > 0  && !obj.isExpanded) {  obj.isExpanded = true; this.getNextLevel(obj); }
            if (obj.oid === node.oid) {
              obj.nodes = (obj.nodes.length > 0 && !obj.nodes[0].class) ? [] : obj.nodes;
              if (!node.isExpanded) {
                const chilnodes = Object.assign([], obj.children);
                obj.nodes = this.loadingNodes(chilnodes);
                return false;
              }
              result.msg.forEach( robj => {
                const chilnodes = Object.assign([], robj.children);
                robj.nodes = this.loadingNodes(chilnodes);
                 obj.nodes.push(robj);
                 this.uiTreeDataChange.next(this.uiTreeData);
              });
            } else {
              obj.nodes.forEach( obj1 => {
                obj1.nodes = (!obj1.nodes) ? [] : obj1.nodes;
                // if (obj1.nodes.length > 0  && !obj1.isExpanded) {  obj1.isExpanded = true; this.getNextLevel(obj1); }
                if (obj1.oid === node.oid) {
                  obj1.nodes = (obj1.nodes.length > 0 && !obj1.nodes[0].class) ? [] : obj1.nodes;
                  if (!node.isExpanded) {
                    const chilnodes = Object.assign([], obj1.children);
                    obj1.nodes = this.loadingNodes(chilnodes);
                    return false;
                  }
                  result.msg.forEach( robj1 => {
                    const chilnodes = Object.assign([], robj1.children);
                    robj1.nodes = this.loadingNodes(chilnodes);
                     obj1.nodes.push(robj1);
                     this.uiTreeDataChange.next(this.uiTreeData);
                  });
                } else {
                  obj1.nodes.forEach( obj2 => {
                    obj2.nodes = (!obj2.nodes) ? [] : obj2.nodes;
                    // if (obj2.nodes.length > 0  && !obj2.isExpanded) {  obj2.isExpanded = true; this.getNextLevel(obj2); }
                    if (obj2.oid === node.oid) {
                      obj2.nodes = (obj2.nodes.length > 0 && !obj2.nodes[0].class) ? [] : obj2.nodes;
                      if (!node.isExpanded) {
                        const chilnodes = Object.assign([], obj2.children);
                        obj2.nodes = this.loadingNodes(chilnodes);
                        return false;
                      }
                      result.msg.forEach( robj2 => {
                        const chilnodes = Object.assign([], robj2.children);
                        robj2.nodes = this.loadingNodes(chilnodes);
                         obj2.nodes.push(robj2);
                         this.uiTreeDataChange.next(this.uiTreeData);
                      });
                    } else {
                      obj2.nodes.forEach( obj3 => {
                        obj3.nodes = (!obj3.nodes) ? [] : obj3.nodes;
                        // if (obj3.nodes.length > 0  && !obj3.isExpanded) {  obj3.isExpanded = true; this.getNextLevel(obj3); }
                        if (obj3.oid === node.oid) {
                          obj3.nodes = (obj3.nodes.length > 0 && !obj3.nodes[0].class) ? [] : obj3.nodes;
                          if (!node.isExpanded) {
                            const chilnodes = Object.assign([], obj3.children);
                            obj3.nodes = this.loadingNodes(chilnodes);
                            return false;
                          }
                          result.msg.forEach( robj3 => {
                            const chilnodes = Object.assign([], robj3.children);
                            robj3.nodes = this.loadingNodes(chilnodes);
                             obj3.nodes.push(robj3);
                             this.uiTreeDataChange.next(this.uiTreeData);
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      } else {

      }
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from 'src/app/_services/form-control.service';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  styleUrls: ['./dynamic-control.component.scss']
})
export class DynamicControlComponent implements OnInit {

  constructor(private _formControlService: FormControlService) {
  }

  @Input() outputValue: any;
  @Input() formElement: any;
  @Input() form: FormGroup;

  get isValid() {
    return this.form.controls[this.formElement.key].valid;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.form === undefined) {
    this.form = this._formControlService.toFormControl(this.formElement);
    }
  }

  selectedFile(file, key) {
    this.outputValue[key] = file;
  }

}

import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-calander',
  templateUrl: './custom-calander.component.html',
  styleUrls: ['./custom-calander.component.scss']
})
export class CustomCalanderComponent implements OnInit {

  @Input() _inputarray: any = [];
  @Input() _bindArray: any = [];
  @Output() _finalValueChange = new EventEmitter();
  @Output() _cancelClick = new EventEmitter();
  selectedArry: any = [];
  savedArray: any = [];
  constructor() { }

  ngOnInit() {
    this._bindArray.forEach(element => {
      this.selectedArry[element] = element;
      this.savedArray[element] = element;
    });
  }

  array = [];
  itemClick(val) {
    const index: number = this.selectedArry.indexOf(val);
    if (index !== -1) {
      this.selectedArry.splice(index, 1);
      this.array = Object.assign([], this.selectedArry);
      this.selectedArry = [];
      this.array.forEach(element => {
        this.selectedArry[element] = element;
      });
    } else {
      this.selectedArry[val] = val;
    }
  }

  setValues() {
    this.savedArray = Object.assign([], this.selectedArry);
    this._finalValueChange.emit(this.selectedArry);
  }

  onCancel() {
    this.selectedArry = Object.assign([], this.savedArray);
    this._cancelClick.emit();
  }
}

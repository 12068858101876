import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailCheckDirective } from './email-check.directive';
import { PasswordCheckDirective } from './password-check.directive';
import {NameCheckDirective} from './name-check.directive';
import {UrlCheckDirective} from './url-check.directive';
@NgModule({
  declarations: [EmailCheckDirective, PasswordCheckDirective, NameCheckDirective, UrlCheckDirective],
  imports: [
    CommonModule
  ],
  exports: [EmailCheckDirective, PasswordCheckDirective, NameCheckDirective, UrlCheckDirective]
})
export class DirectivesModule { }

import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import {CommonService} from '../../_services/common.services';
import {QueryParamsService} from '../../_services/query-params.service';
import {ModalService} from '../../_services/modal.service';
import {NavService} from '../../_services/NavService';
import {AuthenticationService} from '../../_services/authentication.service';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})

/*this.queryBuilder = {
      key: 'name',
      data: [],
      isUrl: false,
      url: '/vulnerability/api/company',
      skip: 0,
      limit: 200,
      method: 'get',
      query: { 'query': { 'bool': { 'must': [{'match': {'family.keyword': 'company'}}, {'match': {'species.keyword': 'company'}}] } } }
    };
*/

export class SelectSearchComponent implements OnInit {
  @Input() queryBuilder: any;
  @Output() selectedValue = new EventEmitter();
  public siteServerSideCtrl: FormControl = new FormControl();
  public siteServerSideFilteringCtrl: FormControl = new FormControl();
  public searching = false;
  public filteredServerSideSites: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected _onDestroy = new Subject<void>();
  Objectkeys = Object.keys;
  _selectedSite: any; selectedItem: any;
  sites: any;
  constructor(public modalService: ModalService, private readonly router: Router, public baseService: BaseRequestService,
              public commonService: CommonService, public _queryParams: QueryParamsService,
              public navService: NavService, public authService: AuthenticationService, private _loaderService: LoaderService) { }

  ngOnInit() {
    this._selectedSite = undefined; let query = {};
    if (this.queryBuilder.isUrl) {
      query = this.queryBuilder.query;
      this.queryBuilder.placeHolder = (this.queryBuilder.placeHolder) ? this.queryBuilder.placeHolder : 'Choose Item';
      this.queryBuilder.method = (this.queryBuilder.method) ? this.queryBuilder.method : 'get';
      this.queryBuilder.limit = (this.queryBuilder.limit) ? this.queryBuilder.limit : 200;
      this.queryBuilder.skip = (this.queryBuilder.skip) ? this.queryBuilder.skip : 0;
      if (!this.queryBuilder.paramCall) {
        this.getSite();
      }
    } else {
      this.sites = Object.assign([], this.queryBuilder.data);
      this.filteredServerSideSites.next(this.sites);
      this.siteServerSideCtrl.setValue(this.sites[0]);
      this._loaderService.selectedSiteChange(this.sites[0]);
      this.selectedValue.next(this.sites[0]);
    }
    this.siteServerSideFilteringCtrl.valueChanges.pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(800),
        map(search => {
          this._selectedSite = undefined;
          if (!this.sites) {
            return [];
          }
          if (this.queryBuilder.isUrl) {
            const matchObj = {regexp: {}};
            matchObj.regexp[this.queryBuilder.key] = {'value': '.*' + search.toLowerCase() + '.*'};
            if (query['query']) {
              query['query'].bool.must.push(matchObj);
            }
            this.baseService.doRequest(`${this.queryBuilder.url}`, this.queryBuilder.method, null,
              {skip: this.queryBuilder.skip, limit: this.queryBuilder.limit, query: query}).subscribe(result => {
              if (result.status === 'ok') {
                result.msg.data.sort((a, b) => {
                  const c = a[this.queryBuilder.key];
                  const d = b[this.queryBuilder.key];
                  if (c < d) {
                    return -1;
                  } else if (c > d) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
                this.filteredServerSideSites.next(result.msg.data);
                this.searching = false;
              } else {
                this.searching = false;
              }
            });
          } else {
            this.sites.sort((a, b) => {
            const c = a.name.toLowerCase();
            const d = b.name.toLowerCase();
            if (c < d) {
              return -1;
            } else if (c > d) {
              return 1;
            } else {
              return 0;
            }
          });
            const filterText = this.sites.filter(company => company.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
            if (filterText.length > 0) {
              this.filteredServerSideSites.next(filterText);
            } else {
              this.filteredServerSideSites.next(this.sites);
            }
            this.searching = false;
          }
          // simulate server fetching and filtering data
          return this.sites.filter(site => site.name.toLowerCase().indexOf(search) > -1);
        }),
        delay(100)
      ).subscribe(filteredSites => {
        if (!this.searching) {
          // this.filteredServerSideSites.next(filteredSites);
        }
        this.searching = false;
      }, error => {
        // no errors in our simulated example
        this.searching = false;
        // handle error...
      });
  }
  updateCurrentSite(event) {
    this.selectedValue.next(event);
    this._selectedSite = event.name;
  }

  closeCurrentSite(event) {
    if (!event && !this._selectedSite && !this.queryBuilder) {
      this.getSite();
    }
  }
  getSite() {
    this.baseService.doRequest(`${this.queryBuilder.url}`, this.queryBuilder.method, null,
      { skip: this.queryBuilder.skip, limit: this.queryBuilder.limit, query: this.queryBuilder.query }).subscribe(result => {
      if (result.status === 'ok' && result.msg.data.length > 0) {
        result.msg.data.sort((a, b) => {
          const c = a[this.queryBuilder.key].toLowerCase(); const d = b[this.queryBuilder.key].toLowerCase();
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
        this.sites = result.msg.data;
        this.filteredServerSideSites.next(result.msg.data);
        this.siteServerSideCtrl.setValue(result.msg.data[0]);
        this._loaderService.selectedSiteChange(result.msg.data[0]);
        this.selectedValue.next(result.msg.data[0]);
      }
    });
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  tableProgressOff: Subject<object> = new Subject<object>();
  public status = new EventEmitter();
  public ModelStatus = new EventEmitter();
  public selectedSiteChanged = new EventEmitter();
  public setCompanyTab = new EventEmitter();
  // public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }


  tableProgress(value: boolean): any {
    this.tableProgressOff.next({val: value});
  }

  display(value: boolean, text?: any) {
    this.status.next({ value, text });
  }

  Modeldisplay(value: boolean, text?: any) {
    this.ModelStatus.next({ value, text });
  }
  selectedSiteChange(value: any) {
    this.selectedSiteChanged.next(value);
  }

  changeCompanyTab(value: any) {
    this.setCompanyTab.next(value);
  }

  private data: Number;

  setOption(value) {
    this.data = value;
  }

  getOption() {
    return this.data;
  }

  private tabNo: Number;

  setTabNo(value) {
    this.tabNo = value;
  }

  getTabNo() {
    return this.tabNo;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as shape from 'd3-shape';
import * as d3 from 'd3';

const monthName = new Intl.DateTimeFormat('en-us', { month: 'short' });
const weekdayName = new Intl.DateTimeFormat('en-us', { weekday: 'short' });

function multiFormat(value) {
  if (value < 1000) { return `${value.toFixed(2)}ms`; }
  value /= 1000;
  if (value < 60) { return `${value.toFixed(2)}s`; }
  value /= 60;
  if (value < 60) { return `${value.toFixed(2)}mins`; }
  value /= 60;
  return `${value.toFixed(2)}hrs`;
}

@Component({
  selector: 'app-s-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  @Output() refreshCallback = new EventEmitter();
  @Input() config: any;
  @Input() data: any;
  chartTitle: string;
  chartGroups: any[];
  chart: any;
  graph: { links: any[]; nodes: any[] };
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear
  };

  chartType: string;
  curveType = 'Linear';
  view: any = [];
  width = 900;
  height = 400;
  results: any = [];
  scheme: any = {};
  schemeType = 'ordinal';
  customColors: any;
  animations = true;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxis = false;
  showYAxis = false;
  showGridLines = true;
  roundDomains = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisLabel = '';
  yAxisLabel = '';
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;
  xAxisTickFormatting: any;
  yAxisTickFormatting: any;
  xAxisTicks: any = [];
  yAxisTicks: any = [];
  showDataLabel = false;
  noBarWhenZero = true;
  gradient = false;
  showLabels = true;
  activeEntries: any = [];
  barPadding = 8;
  tooltipDisabled = false;
  tooltipTemplate: any;
  xScaleMax: number;
  xScaleMin: number;
  yScaleMax: number;
  yScaleMin: number;
  roundEdges = true;
  groupPadding = 16;
  labels = false;
  labelFormatting: any;
  trimLabels = true;
  maxLabelLength =20;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;
  tooltipText: any;
  valueFormatting: any;
  nameFormatting: any;
  percentageFormatting: any;
  label = 'Total';
  designatedTotal: number;
  minWidth = 150;
  rangeFillOpacity = 0.25;
  timeline = false;
  autoScale = false;
  curve = this.curves[this.curveType];
  seriesTooltipTemplate: any;
  referenceLines: any = [];
  showRefLines = false;
  showRefLabels = true;
  labelTrim = true;
  labelTrimSize = 10;
  showSeriesOnHover = true;
  yAxisMinScale: number;
  innerPadding: any = 8;
  cardColor: string;
  bandColor: string;
  textColor: string;
  emptyColor = 'rgba(0,0,0,0)';
  min = 0;
  max = 100;
  units: string;
  bigSegments = 10;
  smallSegments = 5;
  showAxis = true;
  axisTickFormatting: any;
  angleSpan = 240;
  startAngle = -120;
  showText = true;
  value = 0;
  previousValue: number;
  fitContainer = false;
  showRefresh = false;

  constructor() { }
  ngOnInit() {
    if (this.config) {
      this.loadData();
    }

  }
  deactivate(event) {
    console.log(event);
  }
  // activate(event) {
  //   console.log(event);
  // }
  loadData() {
    this.results = this.data;
    this.chartTitle = this.config.chartTitle;
    this.chartType = this.config.chartType;
    this.curveType = this.config.curveType !== undefined ? this.config.curveType : this.curveType;
    this.width = this.config.width !== undefined ? this.config.width : this.width;
    this.height = this.config.height !== undefined ? this.config.height : this.height;
    this.view = [this.width, this.height];
    this.colorScheme = this.config.colorScheme !== undefined ? this.config.colorScheme : this.colorScheme;
    this.schemeType = this.config.schemeType !== undefined ? this.config.schemeType : this.schemeType;
    this.customColors = this.config.customColors !== undefined ? this.config.customColors : this.customColors;

    this.animations = this.config.animations !== undefined ? this.config.animations : this.animations;
    this.showLegend = this.config.Legend !== undefined ? this.config.Legend : this.showLegend;
    this.legendTitle = this.config.legendTitle !== undefined ? this.config.legendTitle : this.legendTitle;
    this.showXAxis = this.config.XAxis !== undefined ? this.config.XAxis : this.showXAxis;
    this.showYAxis = this.config.YAxis !== undefined ? this.config.YAxis : this.showYAxis;

    this.showGridLines = this.config.showGridLines !== undefined ? this.config.showGridLines : this.showGridLines;
    this.roundDomains = this.config.roundDomains !== undefined ? this.config.roundDomains : this.roundDomains;
    this.showXAxisLabel = this.config.showXAxisLabel !== undefined ? this.config.showXAxisLabel : this.showXAxisLabel;
    this.showYAxisLabel = this.config.showYAxisLabel !== undefined ? this.config.showYAxisLabel : this.showYAxisLabel;
    this.xAxisLabel = this.config.xAxisLabel !== undefined ? this.config.xAxisLabel : this.xAxisLabel;
    this.yAxisLabel = this.config.yAxisLabel !== undefined ? this.config.yAxisLabel : this.yAxisLabel;
    this.barPadding = this.config.barPadding !== undefined ? this.config.barPadding : this.barPadding;
    this.groupPadding = this.config.groupPadding !== undefined ? this.config.groupPadding : this.groupPadding;
    this.roundEdges = this.config.roundEdges !== undefined ? this.config.roundEdges : this.roundEdges;
    this.xScaleMax = this.config.xScaleMax !== undefined ? this.config.xScaleMax : this.xScaleMax;

    this.noBarWhenZero = this.config.noBarWhenZero !== undefined ? this.config.noBarWhenZero : this.noBarWhenZero;
    this.showDataLabel = this.config.showDataLabel !== undefined ? this.config.showDataLabel : this.showDataLabel;
    this.trimXAxisTicks = this.config.trimXAxisTicks !== undefined ? this.config.trimXAxisTicks : this.trimXAxisTicks;
    this.trimYAxisTicks = this.config.trimYAxisTicks !== undefined ? this.config.trimYAxisTicks : this.trimYAxisTicks;
    this.rotateXAxisTicks = this.config.rotateXAxisTicks !== undefined ? this.config.rotateXAxisTicks : this.rotateXAxisTicks;
    this.maxXAxisTickLength = this.config.maxXAxisTickLength !== undefined ? this.config.maxXAxisTickLength : this.maxXAxisTickLength;
    this.maxYAxisTickLength = this.config.maxYAxisTickLength !== undefined ? this.config.maxYAxisTickLength : this.maxYAxisTickLength;


    this.chartGroups = this.config.chartGroups !== undefined ? this.config.chartGroups : this.chartGroups;
    this.chart = this.config.chart !== undefined ? this.config.chart : this.chart;
    this.graph = this.config.graph !== undefined ? this.config.graph : this.graph;
    this.colorScheme = this.config.colorScheme !== undefined ? this.config.colorScheme : this.colorScheme;
    this.curves = this.config.curves !== undefined ? this.config.curves : this.curves;


    this.curveType = this.config.curveType !== undefined ? this.config.curveType : this.curveType;

    this.xAxisTickFormatting = this.config.xAxisTickFormatting !== undefined ? this.config.xAxisTickFormatting : this.xAxisTickFormatting;
    this.yAxisTickFormatting = this.config.yAxisTickFormatting !== undefined ? this.config.yAxisTickFormatting : this.yAxisTickFormatting;

    this.xAxisTicks = this.config.xAxisTicks !== undefined ? this.config.xAxisTicks : this.xAxisTicks;
    this.yAxisTicks = this.config.yAxisTicks !== undefined ? this.config.yAxisTicks : this.yAxisTicks;
    this.gradient = this.config.gradient !== undefined ? this.config.gradient : this.gradient;
    this.activeEntries = this.config.activeEntries !== undefined ? this.config.activeEntries : this.activeEntries;
    this.tooltipDisabled = this.config.tooltipDisabled !== undefined ? this.config.tooltipDisabled : this.tooltipDisabled;
    this.tooltipTemplate = this.config.tooltipTemplate !== undefined ? this.config.tooltipTemplate : this.tooltipTemplate;
    this.xScaleMin = this.config.xScaleMin !== undefined ? this.config.xScaleMin : this.xScaleMin;
    this.yScaleMax = this.config.yScaleMax !== undefined ? this.config.yScaleMax : this.yScaleMax;
    this.yScaleMin = this.config.yScaleMin !== undefined ? this.config.yScaleMin : this.yScaleMin;


    this.labels = (this.config.labels) ? this.config.labels : this.labels;
    this.labelFormatting = this.config.labelFormatting !== undefined ? this.config.labelFormatting : this.labelFormatting;
    this.trimLabels = this.config.trimLabels !== undefined ? this.config.trimLabels : this.trimLabels;
    this.maxLabelLength = this.config.maxLabelLength !== undefined ? this.config.maxLabelLength : this.maxLabelLength;
    this.explodeSlices = this.config.explodeSlices !== undefined ? this.config.explodeSlices : this.explodeSlices;
    this.doughnut = this.config.doughnut !== undefined ? this.config.doughnut : this.doughnut;
    this.arcWidth = this.config.yScalarcWidtheMin !== undefined ? this.config.arcWidth : this.arcWidth;
    this.tooltipText = this.config.tooltipText !== undefined ? this.config.tooltipText : this.tooltipText;
    this.valueFormatting = this.config.valueFormatting !== undefined ? this.config.valueFormatting : this.valueFormatting;
    this.nameFormatting = this.config.nameFormatting !== undefined ? this.config.nameFormatting : this.nameFormatting;
    this.percentageFormatting = this.config.percentageFormatting !== undefined ?
      this.config.percentageFormatting : this.percentageFormatting;
    this.label = this.config.label !== undefined ? this.config.label : this.label;
    this.designatedTotal = this.config.designatedTotal !== undefined ? this.config.designatedTotal : this.designatedTotal;
    this.minWidth = this.config.minWidth !== undefined ? this.config.minWidth : this.minWidth;
    this.rangeFillOpacity = this.config.rangeFillOpacity !== undefined ? this.config.rangeFillOpacity : this.rangeFillOpacity;

    this.timeline = this.config.timeline !== undefined ? this.config.timeline : this.timeline;
    this.autoScale = this.config.autoScale !== undefined ? this.config.autoScale : this.autoScale;
    this.curve = this.config.curve !== undefined ? this.config.curve : this.curve;
    this.seriesTooltipTemplate = this.config.seriesTooltipTemplate !== undefined ?
      this.config.seriesTooltipTemplate : this.seriesTooltipTemplate;
    this.referenceLines = this.config.referenceLines !== undefined ? this.config.referenceLines : this.referenceLines;
    this.showRefLines = this.config.showRefLines !== undefined ? this.config.showRefLines : this.showRefLines;
    this.showRefLabels = this.config.showRefLabels !== undefined ? this.config.showRefLabels : this.showRefLabels;
    this.labelTrim = this.config.labelTrim !== undefined ? this.config.labelTrim : this.labelTrim;


    this.labelTrimSize = this.config.labelTrimSize !== undefined ? this.config.labelTrimSize : this.labelTrimSize;
    this.showSeriesOnHover = this.config.showSeriesOnHover !== undefined ? this.config.showSeriesOnHover : this.showSeriesOnHover;
    this.yAxisMinScale = this.config.yAxisMinScale !== undefined ? this.config.yAxisMinScale : this.yAxisMinScale;
    this.innerPadding = this.config.innerPadding !== undefined ? this.config.innerPadding : this.innerPadding;
    this.cardColor = this.config.cardColor !== undefined ? this.config.cardColor : this.cardColor;
    this.bandColor = this.config.bandColor !== undefined ? this.config.bandColor : this.bandColor;
    this.textColor = this.config.textColor !== undefined ? this.config.textColor : this.textColor;
    this.emptyColor = this.config.emptyColor !== undefined ? this.config.emptyColor : this.emptyColor;
    this.min = this.config.min !== undefined ? this.config.min : this.min;
    this.max = this.config.max !== undefined ? this.config.max : this.max;
    this.units = this.config.units !== undefined ? this.config.units : this.units;
    this.bigSegments = this.config.bigSegments !== undefined ? this.config.bigSegments : this.bigSegments;
    this.smallSegments = this.config.smallSegments !== undefined ? this.config.smallSegments : this.smallSegments;
    this.showAxis = this.config.showAxis !== undefined ? this.config.showAxis : this.showAxis;
    this.axisTickFormatting = this.config.axisTickFormatting !== undefined ? this.config.axisTickFormatting : this.axisTickFormatting;
    this.angleSpan = this.config.angleSpan !== undefined ? this.config.angleSpan : this.angleSpan;
    this.startAngle = this.config.startAngle !== undefined ? this.config.startAngle : this.startAngle;
    this.showText = this.config.showText !== undefined ? this.config.showText : this.showText;
    this.value = this.config.value !== undefined ? this.config.value : this.value;
    this.previousValue = this.config.previousValue !== undefined ? this.config.previousValue : this.previousValue;
    this.fitContainer = this.config.fitContainer !== undefined ? this.config.fitContainer : this.fitContainer;

    if (this.fitContainer) {
      this.view = undefined;
    }
    this.showRefresh = this.config.showRefresh !== undefined ? this.config.showRefresh : this.showRefresh;
  }

  RefreshChartData() {
    this.refreshCallback.emit();
  }
  applyDimensions() {
    this.view = [this.width, this.height];
  }

  toggleFitContainer(event) {
    this.fitContainer = event;

    if (this.fitContainer) {
      this.view = undefined;
    } else {
      this.applyDimensions();
    }
  }

  setLabelFormatting(c) {
    var r = this.data.filter(x => x.data.name == c);
    return ` ${r[0].data.value}`.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');//${r[0].data.name}
  }
  // selectChart(chartSelector) {
  //   this.chartType = chartSelector = chartSelector.replace('/', '');
  //   this.location.replaceState(this.chartType);

  //   for (const group of this.chartGroups) {
  //     this.chart = group.charts.find(x => x.selector === chartSelector);
  //     if (this.chart) break;
  //   }

  //   this.linearScale = false;
  //   this.yAxisLabel = 'GDP Per Capita';
  //   this.xAxisLabel = 'Country';

  //   this.width = 700;
  //   this.height = 300;

  //   Object.assign(this, this.chart.defaults);

  //   if (!this.fitContainer) {
  //     this.applyDimensions();
  //   }
  // }
  // onActivate() {
  // }
  getInterpolationType(curveType) {
    return this.curves[curveType] || this.curves['default'];
  }
  // setColorScheme(name) {
  //   this.selectedColorScheme = name;
  //   this.colorScheme = this.colorSets.find(s => s.name === name);
  // }
  // getCalendarData(): any[] {
  //   // today
  //   const now = new Date();
  //   const todaysDay = now.getDate();
  //   const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

  //   // Monday
  //   const thisMonday = new Date(thisDay.getFullYear(), thisDay.getMonth(), todaysDay - thisDay.getDay() + 1);
  //   const thisMondayDay = thisMonday.getDate();
  //   const thisMondayYear = thisMonday.getFullYear();
  //   const thisMondayMonth = thisMonday.getMonth();

  //   // 52 weeks before monday
  //   const calendarData = [];
  //   const getDate = d => new Date(thisMondayYear, thisMondayMonth, d);
  //   for (let week = -52; week <= 0; week++) {
  //     const mondayDay = thisMondayDay + week * 7;
  //     const monday = getDate(mondayDay);

  //     // one week
  //     const series = [];
  //     for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
  //       const date = getDate(mondayDay - 1 + dayOfWeek);

  //       // skip future dates
  //       if (date > now) {
  //         continue;
  //       }

  //       // value
  //       const value = dayOfWeek < 6 ? date.getMonth() + 1 : 0;

  //       series.push({
  //         date,
  //         name: weekdayName.format(date),
  //         value
  //       });
  //     }

  //     calendarData.push({
  //       name: monday.toString(),
  //       series
  //     });
  //   }

  //   return calendarData;
  // }

  // calendarAxisTickFormatting(mondayString: string) {
  //   const monday = new Date(mondayString);
  //   const month = monday.getMonth();
  //   const day = monday.getDate();
  //   const year = monday.getFullYear();
  //   const lastSunday = new Date(year, month, day - 1);
  //   const nextSunday = new Date(year, month, day + 6);
  //   return lastSunday.getMonth() !== nextSunday.getMonth() ? monthName.format(nextSunday) : '';
  // }

  // calendarTooltipText(c): string {
  //   return `
  //     <span class="tooltip-label">${c.label} • ${c.cell.date.toLocaleDateString()}</span>
  //     <span class="tooltip-val">${c.data.toLocaleString()}</span>
  //   `;
  // }

  // pieTooltipText({ data }) {
  //   const label = formatLabel(data.name);
  //   const val = formatLabel(data.value);

  //   return `
  //     <span class="tooltip-label">${label}</span>
  //     <span class="tooltip-val">$${val}</span>
  //   `;
  // }

  // dollarValueFormat(c): string {
  //   return `\$${c.value.toLocaleString()}`;
  // }

  // getStatusData() {
  //   const sales = Math.round(1e4 * Math.random());
  //   const dur = 36e5 * Math.random();
  //   return this.calcStatusData(sales, dur);
  // }

  // calcStatusData(sales = this.statusData[0].value, dur = this.statusData[2].value) {
  //   const ret = sales * this.salePrice;
  //   const cost = ((sales * dur) / 60 / 60 / 1000) * this.personnelCost;
  //   const ROI = (ret - cost) / cost;
  //   return [
  //     {
  //       name: 'Sales',
  //       value: sales
  //     },
  //     {
  //       name: 'Gross',
  //       value: ret,
  //       extra: { format: 'currency' }
  //     },
  //     {
  //       name: 'Avg. Time',
  //       value: dur,
  //       extra: { format: 'time' }
  //     },
  //     {
  //       name: 'Cost',
  //       value: cost,
  //       extra: { format: 'currency' }
  //     },
  //     {
  //       name: 'ROI',
  //       value: ROI,
  //       extra: { format: 'percent' }
  //     }
  //   ];
  // }

  // statusValueFormat(c): string {
  //   switch (c.data.extra ? c.data.extra.format : '') {
  //     case 'currency':
  //       return `\$${Math.round(c.value).toLocaleString()}`;
  //     case 'time':
  //       return multiFormat(c.value);
  //     case 'percent':
  //       return `${Math.round(c.value * 100)}%`;
  //     default:
  //       return c.value.toLocaleString();
  //   }
  // }

  // valueFormatting(value: number): string {
  //   return `${Math.round(value).toLocaleString()} €`;
  // }

  // currencyFormatting(value: number) {
  //   return `\$${Math.round(value).toLocaleString()}`;
  // }

  // gdpLabelFormatting(c) {
  //   return `${c.label}<br/><small class="number-card-label">GDP Per Capita</small>`;
  // }

  // statusLabelFormat(c): string {
  //   return `${c.label}<br/><small class="number-card-label">This week</small>`;
  // }

  // generatePlotData() {
  //   if (!this.mathFunction) {
  //     return [];
  //   }
  //   const twoPi = 2 * Math.PI;
  //   const length = 25;
  //   const series = Array.apply(null, { length }).map((d, i) => {
  //     const x = i / (length - 1);
  //     const t = x * twoPi;
  //     return {
  //       name: ~~(x * 360),
  //       value: this.mathFunction(t)
  //     };
  //   });

  //   return [
  //     {
  //       name: this.mathText,
  //       series
  //     }
  //   ];
  // }

  // getFunction(text = this.mathText) {
  //   try {
  //     text = `with (Math) { return ${this.mathText} }`;
  //     // tslint:disable-next-line:function-constructor
  //     const fn = new Function('x', text).bind(Math);
  //     return typeof fn(1) === 'number' ? fn : null;
  //   } catch (err) {
  //     return null;
  //   }
  // }

  // treemapProcess(sumBy = this.sumBy) {
  //   this.sumBy = sumBy;
  //   const children = treemap[0];
  //   const value = sumBy === 'Size' ? sumChildren(children) : countChildren(children);
  //   this.treemap = [children];
  //   this.treemapPath = [{ name: 'Top', children: [children], value }];

  //   function sumChildren(node) {
  //     return (node.value = node.size || d3.sum(node.children, sumChildren));
  //   }

  //   function countChildren(node) {
  //     return (node.value = node.children ? d3.sum(node.children, countChildren) : 1);
  //   }
  // }

  // treemapSelect(item) {
  //   let node;
  //   if (item.children) {
  //     const idx = this.treemapPath.indexOf(item);
  //     this.treemapPath.splice(idx + 1);
  //     this.treemap = this.treemapPath[idx].children;
  //     return;
  //   }
  //   node = this.treemap.find(d => d.name === item.name);
  //   if (node.children) {
  //     this.treemapPath.push(node);
  //     this.treemap = node.children;
  //   }
  // }

  // getFlag(country) {
  //   return this.countries.find(c => c.name === country).emoji;
  // }

  // onFilter(event) {
  //   console.log('timeline filter', event);
  // }

  // /*
  // **
  // Combo Chart
  // **
  // [yLeftAxisScaleFactor]="yLeftAxisScale" and [yRightAxisScaleFactor]="yRightAxisScale"
  // exposes the left and right min and max axis values for custom scaling, it is probably best to
  // scale one axis in relation to the other axis but for flexibility to scale either the left or
  // right axis bowth were exposed.
  // **
  // */

  // yLeftAxisScale(min, max) {
  //   return { min: `${min}`, max: `${max}` };
  // }

  // yRightAxisScale(min, max) {
  //   return { min: `${min}`, max: `${max}` };
  // }

  // yLeftTickFormat(data) {
  //   return `${data.toLocaleString()}`;
  // }

  // yRightTickFormat(data) {
  //   return `${data}%`;
  // }
  // /*
  // **
  // End of Combo Chart
  // **
  // */

  onSelect(event) {
    console.log(event);
  }

  // dblclick(event) {
  //   console.log('Doube click', event);
  // }

  // /*
  // **
  // Bubble Chart Interactive Demo
  // **
  // */

  // bubbleDemoProcess(dataFromServer) {
  //   this.bubbleDemoChart.setDataFromServer(dataFromServer);
  //   this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  // }

  // getBubbleInteractiveTitle() {
  //   return this.bubbleDemoChart.getChartTitle();
  // }

  // bubbleShowDrilldownResetLink() {
  //   return this.bubbleDemoChart.getDrilldownDepth() > 0;
  // }

  // onClickResetBubbleInteractiveDrill() {
  //   this.bubbleDemoChart.resetDrilldown();
  //   this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  // }

  // onSelectBubbleInteractivePoint(event) {
  //   this.bubbleDemoChart.drilldown(event);
  //   this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  // }

  // onSelect(event) {
  //   console.log(event);
  // }

}

import { Location } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginData } from '../login/login/login.component';
import { BaseRequestService } from './base.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.services';
import { UserIdleService } from 'angular-user-idle';
import { QueryParamsService } from './query-params.service';
import { LoaderService } from './loader.service';

interface User {
  userName?: string;
  first_name?: string;
  last_name?: string;
  wsKey?: string;
  _key?: string;
  _id?: string;
  subDomain?: string;
  isNewSetup?: boolean;
  email?: string;
  isMFAEnabled: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  isAuthenticated: boolean;
  secretMessage: any;
  currentUser?: User;
  resultOut: any;
  tmpData: any; Objectkeys = Object.keys;
  cspChange: Subject<object> = new Subject<object>();
  resultOutChange: Subject<object> = new Subject<object>();
  tfaOutChange: Subject<object> = new Subject<object>();
  currentUserChange: Subject<object> = new Subject<object>();
  companyHashMap = {};
  private sessionData = {idle: 300, timeout: 180, ping: 120};
  constructor(
    readonly router: Router, public toastr: ToastrService,
    public baseRequestService: BaseRequestService,
    private _queryParams: QueryParamsService,
    private commonService: CommonService,
    readonly location: Location,
    private userIdle: UserIdleService,
    private loaderService: LoaderService
  ) {
    this.isAuthenticated = undefined;
    this.currentUser = undefined;
    this.resultOut = undefined;
    this.currentUserChange.next(this.currentUser);
    this.resultOutChange.next(this.resultOut);
  }
  setCSP() {
    this.cspChange.next({csp: true});
  }
  isBase64(str) {
    try {
      return atob(btoa(str)) === str;
    } catch (err) {
      return false;
    }
  }
  login_(loginData: LoginData) {
    this.resultOut = Object.assign({}, loginData);
    console.log('Login Data...');
    const authToken = 'Basic ' + btoa(loginData.email + ':' + loginData.password);
    this.baseRequestService.doRequest('/zapi/login', 'post', loginData).subscribe(result => {
      /*, null,
      new HttpHeaders({'Content-Type': 'application/json', 'Authorization': authToken})*/
      this.resultOut = result;
      /*if (this.resultOut === 'Logged in successfully' || result.status === 'ok') {
        this.afterLogin(result.msg, false);
      } else {
        this.toastr.error(result.msg);
        this.afterLogout();
      }*/

      if (this.resultOut === 'Logged in successfully' || result.status === 'ok') {
        if (result.msg.location) {
          window.location = result.msg.location;
        } else {
          this.afterLogin(result.msg, false);
        }
      } else if (result['requiredMFA']) {
          this.tfaOutChange.next({mfa: true});
      } else  {
        this.toastr.error(result.msg);
        this.afterLogout();
      }
    });
  }
  getSessionSettings() {
    const queryParam = {
      'query': { 'bool': { 'must': [ {'match': {'family.keyword': 'settings'}}, {'match': {'species.keyword': 'user'}},
            {'match': {'tablename.keyword': 'session_settings'}}] } }
    };
    this.baseRequestService.doRequest('/vulnerability/api/user_settings',
      'get', null, {query: queryParam}).subscribe(res => {
      if (res.status === 'ok') {
        let session = {idle: 1800, timeout: 900, ping: 450};
        if (res.msg['data'].length > 0) { session = JSON.parse(res.msg['data'][0]['userdata']); }
        this.setSession(session);
      }
    });
  }
  setSession(session) {
    this.userIdle.setConfigValues({idle: session.idle, timeout: session.timeout, ping: session.ping});

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.logout());
  }
  login(loginData: any) {
    this.loaderService.display(true);
    this.baseRequestService.doRequest('/usermgmt/api/auth', 'post', loginData).subscribe(result => {
      if (result['status'] === 'error') {
        this.resultOutChange.next(result);
        this.loaderService.display(false);
      } else {
        this.resultOut = result;
        //  console.log(result);
        localStorage.setItem('uasaeara', btoa(loginData.email));
        if (this.resultOut) {

          // Start watching for user inactivity.
          this.userIdle.startWatching();

          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe(count => console.log(count));

          // Start watch when time is up.
          this.userIdle.onTimeout().subscribe(() => this.logout());

          if (this.resultOut.location) {
            window.location = this.resultOut.location;
            this.companyHashCall();
            this.loaderService.display(false);
          } else if (this.resultOut.requiredMFA) {
            this.tfaOutChange.next({mfa: true});
          } else {
            this.afterLogin(this.resultOut, false);
          }
        } else {
          this.loaderService.display(false);
          this.afterLogout();
        }
      }
    });
  }
  logout() {
    this.baseRequestService
      .doRequest('/usermgmt/api/logout', 'post')
      .subscribe(() => this.afterLogout(), () => this.afterLogout());
  }

  checkSession() {
    this.loaderService.display(true);
    const url = (window.location.pathname === '/admin') ? '/api/local_auth' : '/usermgmt/api/o_auth2_authorization_code_grant_client';
    this.baseRequestService.doRequest(url, 'get').subscribe(
      result => {
        this.loaderService.display(false);
        this.tmpData = result.msg;
        if (this.tmpData === 'Already logged in' || result.status === 'ok') {
          const shouldCheckPath = (this.tmpData.isNewSetup) ? false : true;
          this.afterLogin(this.tmpData, shouldCheckPath);
        } else {
          this.afterLogout();
        }
      },
      error => {
        if (error['ok'] === false) {
          if (error['status'] === 401) {
            if (error.error.location === undefined) {

            } else {
              const path = this.location.path();
              //   console.log(path);
              //   console.log(error.error['location']);
              if (path === '' || path === '/' || path === '/login') {
                window.location = error.error['location'];
              }
            }
            this.isAuthenticated = false;
            this.currentUser = undefined;
          } else {
            this.afterLogout();
            console.log('Checksession error came..');
          }
        } else {
          this.afterLogout();
          console.log('Checksession error came..');
        }
      }
    );
  }
  checkSession_() {
    this.loaderService.display(true);
    const url = (window.location.pathname === '/admin') ? '/api/local_auth' : '/api/o_auth2_authorization_code_grant_client';
    this.baseRequestService.doRequest(`/zapi/login`, 'get').subscribe(
      result => {
        this.loaderService.display(false);
        this.tmpData = result;
        if (this.tmpData === 'Already logged in' || result.status === 'ok') {
          this.afterLogin(this.tmpData, true);
        } else {
          this.afterLogout();
        }
      }
    );
  }

  private afterLogin_(result: User, shouldCheckPath: boolean) {
    this.isAuthenticated = true; this.currentUser = result;
 //   console.log(this.location.path());
    if (!shouldCheckPath || this.location.path() === '' || this.location.path() === '/') {
      if (result && result.isNewSetup) {
        this.router.navigateByUrl('/welcome');
      } else {
        this.router.navigateByUrl('/companies');
      }
    } else if (this.location.path() === '/login' && localStorage.getItem('path') !== undefined) {
      this.router.navigateByUrl(localStorage.getItem('path'));
    }
  }
  companyHashCall() {
    this.companyHashMap = {};
    this.loaderService.display(true);
    this.baseRequestService.doRequest(`/vulnerability/api/company`, 'get', 'null',
      {
        query: this._queryParams.companyQuery(),
        skip: 0,
        limit: 1000
      })
      .subscribe(result => {
        this.loaderService.display(false);
        if (result.status === 'ok') {
          if (result.msg.data.length === 0) {
            this.loaderService.display(true, 'Getting aruba central information');
            this.baseRequestService.doRequest(`/integrations/api/credentials/aruba/getCredentials`, 'post').subscribe(ret => {
                this.loaderService.display(false);
                if (result.status === 'ok') {
                    if (!this.Objectkeys(ret.msg).length) {
                      this.router.navigateByUrl('/companies/onboarding/1');
                    }
                }
            });
          } else {
            result.msg.data.forEach(obj => {
              this.companyHashMap[obj._id] = obj.name;
            });
          }
        }
      });
  }

  private afterLogin(result: User, shouldCheckPath: boolean) {
    this.companyHashCall();
    this.isAuthenticated = true;
    this.currentUser = result;
    this.loaderService.display(true);

    if (!shouldCheckPath || this.location.path() === '' || this.location.path() === '/') {
      if (result && result.isNewSetup) {
        this.router.navigateByUrl('/welcome');
      } else {
        this.baseRequestService.doRequest(`/vulnerability/api/assets`, 'get')
          .subscribe(dev => {
            this.loaderService.display(false);
            if (dev.status === 'ok' && dev.msg.data.length === 0) {
              this.router.navigateByUrl('/network/start-guide');
            } else {
              this.router.navigateByUrl('/companies');
            }
          });
      }
    } else if (this.location.path() === '/login' && localStorage.getItem('path') !== undefined && localStorage.getItem('path') !== null) {
      this.router.navigateByUrl(localStorage.getItem('path'));
    } else {
      this.router.navigateByUrl('/companies');
    }
    this.getSessionSettings();
    this.loaderService.display(false);
  }

  private afterLogout() {
    this.isAuthenticated = false;
    this.currentUser = undefined;
    localStorage.clear();
    if (this.router.url.indexOf('resetpassword') > -1) {
      // console.log('dont redirect');
    } else {
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 500);
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public CancelText: string,
    public AcceptText: string,
    public cancelColor: string,
    public acceptColor: string,
    public htmlMsg: boolean
  ) {
  }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  CancelText: string;
  AcceptText: string;
  CancelColor: string;
  AcceptColor: string;
  HtmlMsg: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.title = data.title,
    this.message = data.message,
    this.CancelText = data.CancelText,
    this.AcceptText = data.AcceptText;
    this.CancelColor = data.cancelColor,
    this.AcceptColor = data.acceptColor;
    this.HtmlMsg = data.htmlMsg;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }

}


import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  
  @Output() fileCallbackEvent = new EventEmitter();
  
  @Input() mode
  @Input() names
  @Input() url
  @Input() method
  @Input() multiple = true
  @Input() disabled
  @Input() accept
  @Input() maxFileSize
  @Input() auto = true
  @Input() withCredentials
  @Input() invalidFileSizeMessageSummary
  @Input() invalidFileSizeMessageDetail
  @Input() invalidFileTypeMessageSummary
  @Input() invalidFileTypeMessageDetail
  @Input() previewWidth
  @Input() chooseLabel = 'Choose'
  @Input() uploadLabel = 'Upload'
  @Input() cancelLabel = 'Cance'
  @Input() customUpload
  @Input() showUploadButton
  @Input() showCancelButton
  @Input() dataUriPrefix
  @Input() deleteButtonLabel
  @Input() deleteButtonIcon = 'close'
  @Input() showUploadInfo

  @Input() type = 'image'

  /**
   *
   */


  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef

  inputFileName: string

  @Input() files: File[] = []

  constructor(private sanitizer: DomSanitizer) {

  }

  onClick(event) {
    if (this.fileUpload)
      this.fileUpload.nativeElement.click()
  }

  onInput(event) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (this.type !== files[i].type) {
        return false;
      }
    }

  }

  onFileSelected(event) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    console.log('event::::::', event)
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      //if(!this.isFileSelected(file)){
      if (this.validate(file)) {
        //      if(this.isImage(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
        //      }
        if (!this.isMultiple()) {
          this.files = []
        }
        this.files.push(files[i]);
        //  }
      }
      //}
    }
    this.fileCallbackEvent.emit(this.files)
  }

  removeFile(event, file) {
    let ix
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1)
      this.clearInputElement()
    }
  }

  validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === f.size
        && f.type === f.type
      ) {
        return false
      }
    }
    return true
  }

  clearInputElement() {
    this.fileUpload.nativeElement.value = ''
  }


  isMultiple(): boolean {
    return this.multiple
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import { TableComponent } from './table/table.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterTabs } from './router-tab/router-tabs.directive';
import { RouterTab } from './router-tab/router-tab.directive';
import { AppFilterPipeModule } from '../_filters/app.filter-pipe.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ModalComponent } from './modal.component';
import { DynamicFormComponent } from './forms/dynamic-form/dynamic-form.component';
import { DynamicControlComponent } from './forms/dynamic-control/dynamic-control.component';
import { QueryBuilderModule } from 'angular2-query-builder';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ObjectkeysCardComponent } from './objectkeys-card/objectkeys-card.component';
import { FileUploadComponent } from './forms/file-upload/file-upload.component';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { ChartsComponent } from './charts/charts.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GridsterModule } from 'angular-gridster2';
import { DirectivesModule } from '../-directives/-directives.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GaugeComponent } from './gauge/gauge.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { CustomCalanderComponent } from './forms/custom-calander/custom-calander.component';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {NetworkGraphComponent} from '../admin/network-graph/network-graph.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { DownloadpageComponent } from './downloadpage/downloadpage.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    AppFilterPipeModule,
    RouterModule,
    QueryBuilderModule,
    PDFExportModule,
    NgDynamicBreadcrumbModule,
    NgxChartsModule,
    GridsterModule,
    DirectivesModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaterialTimepickerModule,
    MatFormFieldModule, MatSelectModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    QueryBuilderModule,
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    TableComponent,
    NetworkGraphComponent,
    FileUploadComponent,
    AppFilterPipeModule,
    ModalComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    ObjectkeysCardComponent,
    NgDynamicBreadcrumbModule,
    CustomCalanderComponent,
    NgxChartsModule,
    ChartsComponent, GaugeComponent,
    GridsterModule,
    DirectivesModule,
    NgMultiSelectDropDownModule,
    NgxMaterialTimepickerModule,
    SelectSearchComponent,
    DownloadpageComponent
  ],
  declarations: [
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    TableComponent,
    NetworkGraphComponent,
    FileUploadComponent,
    ConfirmDialogComponent,
    ModalComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    ObjectkeysCardComponent,
    ChartsComponent,
    GaugeComponent,
    CustomCalanderComponent,
    SelectSearchComponent,
    DownloadpageComponent
  ],
  entryComponents: [ConfirmDialogComponent, CustomCalanderComponent]
})
export class SharedModule {}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-objectkeys-card',
  templateUrl: './objectkeys-card.component.html',
  styleUrls: ['./objectkeys-card.component.scss']
})
export class ObjectkeysCardComponent implements OnInit {
 Objectkeys = Object.keys;
  @Input() basicInfo;
  @Input() title;

  constructor() { }

  ngOnInit() { }

}

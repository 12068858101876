import { Directive, HostListener, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appUrlCheck]'
})
export class UrlCheckDirective {

  constructor(private el:ElementRef, private _toastr:ToastrService) {
   }

  URL_REGX = new RegExp(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);

  @HostListener('focusout')
  onFocusOut(){
    let current: string = this.el.nativeElement.value;
    if (current != "") {
      if (current && !String(current).match(this.URL_REGX)) {
        this._toastr.error("Invalid Url,The Url should start with http:// or https://");
        this.el.nativeElement.focus();
      }
    }
  }
}

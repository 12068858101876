import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  assetData: any;
  complianceData: any;
  complianceStatsData: any;
  vulnerabilityStatsData: any;
  credsMasterData: any;
  applianceData: any;
  credsData: any;
  vulnerabilityData: any;
  domanisData: any;
  schedulerData: any;

  constructor() {
  }

  getApplicationListQuery() {
    return {
      'query': {
        'bool': {
          'must': [
            {'match': {'family.keyword': 'sw_labelling'}},
            {'match': {'species.keyword': 'sw_labelling'}},
            {'match': {'companyid.keyword': ''}}
          ]
        }
      }
    };
  }

  getCyberAlertQuery() {
    return {
      'query': {
        'bool': {
          'must': [
            {'match': {'family.keyword': 'cyber_alerts'}},
            {'match': {'species.keyword': 'cyber_alerts'}},
            {'match': {'companyid.keyword': ''}}
          ]
        }
      }
    };
  }

  questionBankQuery() {
    return {'query': {'bool': {'must': [{'match': {'family.keyword': 'questionbank'}}, {'match': {'species.keyword': 'questionbank'}}]}}};
  }

  getAlertsQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'alerts'}}, {'match': {'species.keyword': 'alerts'}}, {'match': {'companyid.keyword': ''}}]
        }
      }
    };
  }

  getNotificationAlertsQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'alerts'}},
            {'bool': {'should': [{'match': {'species.keyword': 'alerts'}}]}}
          ]
        }
      }
    };
  }

  assetsQuery() {
    this.assetData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'assets'}},
            {'bool': {'should': [{'match': {'species.keyword': 'assets'}}]}}
          ]
        }
      }
    };
    return this.assetData;
  }

  getMSSDataQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'office365securescore'}},
            {'match': {'species.keyword': 'office365securescore'}}, {'match': {'companyid.keyword': ''}}]
        }
      }
    };
  }

  allVulsByOsQuery() {
    return `{'query': {'bool': {'must': [{'match': {'species.keyword': 'vulnerabilites'}}, {'match': {'family.keyword': 'vulnerability'}},
     {'match': {'vulnerabilitystatus.keyword': 'Open'}}, {'match': {'companyid.keyword': ''}}, {'match': {'os.keyword': ''}}]}}}`;
  }

  assessmentTemplateQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'species.keyword': 'assesmenttemplate'}},
            {'match': {'family.keyword': 'assesments'}}]
        }
      }
    };
    // {'match': {'companyid.keyword': '<>'}}
  }

  getApplianceGrainsQuery() {
    const query = {
      'query': {
        'bool': {
          'must': []
        }
      }
    };
    query.query.bool.must.push({'match': {'family.keyword': 'appliancegrains'}});
    query.query.bool.must.push({'match': {'species.keyword': 'appliancegrains'}});
    return query;
  }

  getExternalScansQuery() {
    return {
      'query': {
        'bool': {
          'must': [
            {'match': {'species.keyword': 'externalassets'}},
            {'match': {'family.keyword': 'externalassets'}},
            {'match': {'companyid.keyword': ''}},
            {'match': {'is_config': true}}
          ]
        }
      }
    };
  }

  getExternalScansDetailsQuery() {
    return {
      'query': {
        'bool': {
          'must': [
            {'match': {'species.keyword': 'externalassets'}},
            {'match': {'family.keyword': 'externalassets'}},
            {'match': {'companyid.keyword': ''}},
            {'match': {'config_ref.keyword': ''}}
          ]
        }
      }
    };
  }

  getExternalScanResultsQuery() {
    return {
      'query': {
        'bool': {
          'must': [
            {'match': {'species.keyword': 'externalscan_results'}},
            {'match': {'family.keyword': 'externalscan_results'}},
            {'match': {'external_assetid.keyword': ''}}
          ]
        }
      }
    };
  }

  getNetalyticsSOPQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'species.keyword': 'netalytics_sop'}},
            {'match': {'family.keyword': 'netalytics_sop'}}]
        }
      }
    };
  }

  complianceQuery() {
    this.complianceData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'compliance'}},
            {'bool': {'should': [{'match': {'species.keyword': 'compliance'}}]}}
          ]
        }
      }
    };
    return this.complianceData;
  }

  complianceWithSuppressedQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'compliance'}},
            {'match': {'species.keyword': 'suppressed'}}]
        }
      }
    };
  }

  complianceWithStatsQuery() {
    this.complianceStatsData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'compliance'}},
            {'bool': {'should': [{'match': {'species.keyword': 'stats'}}]}}
          ]
        }
      }
    };
    return this.complianceStatsData;
  }

  companyQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'company'}},
            {'bool': {'should': [{'match': {'species.keyword': 'company'}}]}}
          ]
        }
      }
    };
  }

  reportQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'reports'}},
            {'bool': {'should': [{'match': {'species.keyword': 'reports'}}]}}
          ]
        }
      }
    };

  }

  vulnerabilityQuery() {
    this.vulnerabilityData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'vulnerability'}}, {'match': {'vulnerabilitystatus.keyword': 'Open'}},
            {'bool': {'should': [{'match': {'species.keyword': 'vulnerabilites'}}]}}
          ]
        }
      }
    };
    return this.vulnerabilityData;
  }

  vulnerabilityWithStatsQuery() {
    this.vulnerabilityStatsData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'vulnerability'}},
            {'bool': {'should': [{'match': {'species.keyword': 'stats'}}]}}
          ]
        }
      }
    };
    return this.vulnerabilityStatsData;
  }

  vulnerabilityWithSuppressedQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'vulnerability'}},
            {'match': {'species.keyword': 'suppressed'}}]
        }
      }
    };
  }

  vulnerabilityWithFalsePositiveQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'vulnerability'}},
            {'match': {'species.keyword': 'falsepositive'}}]
        }
      }
    };
  }

  applianceQuery() {
    this.applianceData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'company'}},
            {'bool': {'should': [{'match': {'species.keyword': 'appliance'}}]}}
          ]
        }
      }
    };
    return this.applianceData;
  }

  snmpQuery(): any {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'species.keyword': 'global'}},
            {'match': {'family.keyword': 'credentials'}}, {'match': {'type.keyword': 'snmp'}}]
        }
      }
    };
  }

  adQuery(): any {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'species.keyword': 'global'}},
            {'match': {'family.keyword': 'credentials'}}, {'match': {'type.keyword': 'ad'}}]
        }
      }
    };
  }

  uncQuery(): any {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'species.keyword': 'global'}},
            {'match': {'family.keyword': 'credentials'}}, {'match': {'type.keyword': 'unc'}}]
        }
      }
    };
  }

  discoveryQuery(): any {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'settings'}},
            {'bool': {'should': [{'match': {'species.keyword': 'discovery'}}]}}
          ]
        }
      }
    };
  }

  credentialsQuery() {
    this.credsData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'credentials'}},
            {'bool': {'should': [{'match': {'species.keyword': 'credentials'}}]}}
          ]
        }
      }
    };
    return this.credsData;
  }

  credentialsWithMasterQuery() {
    this.credsMasterData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'credentials'}},
            {'bool': {'should': [{'match': {'species.keyword': 'master'}}]}}
          ]
        }
      }
    };
    return this.credsMasterData;
  }

  credentialsWithAppQuery() {
    this.credsMasterData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'credentials'}},
            {'bool': {'should': [{'match': {'species.keyword': 'app'}}]}}
          ]
        }
      }
    };
    return this.credsMasterData;
  }

  rolesQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'roles'}}, {'match': {'species.keyword': 'roles'}}],
          'must_not': [{'match': {'internalUse': true}}]
        }
      }
    };
  }

  tasksQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'tasks'}},
            {'bool': {'should': [{'match': {'species.keyword': 'tasks'}}]}}
          ]
        }
      }
    };
  }

  jobsQuery() {
    const s = {
      'query': {
        'bool': {
          'must': [
            {'match': {'family.keyword': 'jobs'}},
            {'match': {'species.keyword': 'jobs'}}
          ],
          'must_not': [{'exists': {'field': 'parentJob'}}]
        }
      }
    };
    return s;

    /* {
    'query': {
      'bool': {
        'must': [{ 'match': { 'family.keyword': 'jobs' } },
        { 'bool': { 'should': [{ 'match': { 'species.keyword': 'jobs' } }] } }
        ]
      }
    }
  }*/
  }

  schedulersQuery() {
    this.schedulerData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'scheduler'}},
            {'bool': {'should': [{'match': {'species.keyword': 'scheduler'}}]}}
          ]
        }
      }
    };
    return this.schedulerData;
  }

  usersQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'users'}}, {'match': {'species.keyword': 'users'}}],
          'must_not': [{'match': {'internalUse': true}}]
        }
      }
    };
    /*{
      'query': {
        'bool': {
          'must': [{ 'match': { 'family.keyword': 'users' } },
          { 'bool': { 'should': [{ 'match': { 'species.keyword': 'users' } }] } }
          ]
        }
      }
    };*/
  }

  userSettingsQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'settings'}},
            {'bool': {'should': [{'match': {'species.keyword': 'user'}}]}}
          ]
        }
      }
    };
  }

  awsQuery() {
    return {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'cloud'}},
            {'bool': {'should': [{'match': {'species.keyword': 'cloud'}}]}}
          ]
        }
      }
    };
  }

  domainsQuery() {
    this.domanisData = {
      'query': {
        'bool': {
          'must': [{'match': {'family.keyword': 'domains'}},
            {'bool': {'should': [{'match': {'species.keyword': 'domains'}}]}}
          ]
        }
      }
    };
    return this.domanisData;
  }

}

import { Component } from '@angular/core';
import {BaseRequestService} from './_services/base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor( baseService: BaseRequestService) {
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControlService } from 'src/app/_services/form-control.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [FormControlService]
})
export class DynamicFormComponent implements OnInit {
  @Input() fnBtnName = 'Save';
  @Input() closeBtnName = 'Close';
  @Input() isCloseBtn = true;
  @Input() isSaveBtn = true;
  @Input() listOfFormElements: any = [];
  @Output() saveCallBack = new EventEmitter();
  @Output() cancelCallBack = new EventEmitter();
  @Input() inProgress = false;
  // @Input() selectedValue: any;
  @Output() valueUpdate = new EventEmitter();
  form: FormGroup;
  @Input() Valuesoutput: {} = {};
  @Output() outputValue: {} = {};
  payLoad = '';
  constructor(private _formService: FormControlService) { }

  ngOnInit() {
    this.form = this._formService.toFormGroup(this.listOfFormElements);
  }

  Save(frmValues: any) {
    this.saveCallBack.emit(frmValues);
  }
  Cancel() {
    this.cancelCallBack.emit();
  }
  ngOnChanges() {
    //this.form = this._formService.toFormGroup(this.listOfFormElements);
  }

  onSubmit() {
    // this.form.value["SelectedDropdown"] = this.selectedValue.value;
    // delete  this.form.value.undefined;
    this.payLoad = JSON.stringify(this.form.value);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  CamelToHumanPipe, DaysHoursSecondsPipe, BytesConvertFilterPipe, EpochToDateFilterPipe, StrToMacPipe, SafePipe, ArrayOrderByPipe,
  FormatTrafficUnitsPipe, DateAgoPipe, FormatCellPipe, UniqueFilterPipe, ArrayToStrPipe, TimeFormat, DateFormat, DateAndTimeFormat,
  CveToDateFilterPipe, MacFilterPipe, AssessmentDateFilterPipe, SplitPipe, FilterPipe, LinkifyPipe, ArrayToStrEllipsisPipe, SearchPipe,
  SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, EllipsisPipe,
} from './app.filter.pipe';

@NgModule({
  declarations: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, EllipsisPipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe, FilterPipe, LinkifyPipe
  ],
  imports: [CommonModule],
  exports: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    FormatTrafficUnitsPipe, SafeHTMLPipe,  TimeAgoPipe, ArraySortPipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe,
    FilterPipe, LinkifyPipe
  ],
  providers: [DatePipe, FilterPipe]
})
export class AppFilterPipeModule {
}

import {Component, Input, OnInit, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {Gauge} from 'src/assets/utils/gauge.min.js';

@Component({
  selector: 'app-s-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit, AfterViewInit {
  @Input() config: any;
  @ViewChild('vgauge', {static: false}) vgauge: ElementRef;
  Gauge_1: any;
  opts = {
    angle: 0.1,
    lineWidth: 0.2,
    pointer: {
      length: 0.5,
      strokeWidth: 0.05,
      color: '#000000'
    },
    staticZones: [
      {strokeStyle: '#30B32D', min: 0, max: 30, height: 2},
      {strokeStyle: '#FFDD00', min: 30, max: 50, height: 1.5},
      {strokeStyle: '#F03E3E', min: 50, max: 100, height: 1},
    ],
    limitMax: false,
    limitMin: false,
    highDpiSupport: true
  };
  constructor() {
    this.init();
  }
  init() {
    if (this.vgauge) {
      if (this.config.staticZones) {
        this.opts.staticZones = Object.assign([], this.config.staticZones);
      }
      this.Gauge_1 = new Gauge(this.vgauge.nativeElement).setOptions(this.opts);
      this.Gauge_1.maxValue = 100;
      this.Gauge_1.setMinValue(0);
      this.Gauge_1.animationSpeed = 35;
      this.Gauge_1.set(this.config.risk_score);
    } else {
      setTimeout( () => {
        this.init();
      }, 500);
    }
  }
  ngOnInit() {

  }
  ngAfterViewInit() {
    this.init();
  }
}
